import dayjs from 'dayjs';
import { useState } from 'preact/hooks';
import { IcoBook, IcoChartPie, IcoChat, IcoDocument } from '@components/icons';
import { Case } from '@components/conditional';
import { CalendarDate } from '@components/course-overview';
import { LessonRow, ModuleRow } from 'server/types';
import { useIntl } from 'shared/intl/use-intl';
import { LessonBadges } from '@components/lesson-badges';
import { Pill } from '@components/pill';

export type Lesson = Pick<LessonRow, 'id' | 'title' | 'isPrerequisite'> & {
  isCompleted?: boolean;
  hasDiscussion?: boolean;
  href: string;
};

export type Module = Pick<ModuleRow, 'id' | 'title' | 'isDraft'> & {
  lessons: Lesson[];
  availableOn?: Date;
  isCompleted?: boolean;
  completionRate?: number;
  stats: {
    discussions: number;
    assignments: number;
  };
};

interface Props {
  module: Module;
  index: number;
}

interface LessonItemProps {
  lesson: Lesson;
}

function LessonItem({ lesson }: LessonItemProps) {
  return (
    <a
      href={lesson.href}
      class="rounded-md p-4 flex justify-between gap-2 relative w-full hover:bg-gray-50 dark:hover:bg-gray-800 text-inherit"
    >
      <span class="grow line-clamp-1">{lesson.title}</span>
      <LessonBadges
        lesson={lesson}
        isComplete={lesson.isCompleted}
        hasDiscussion={lesson.hasDiscussion}
      />
    </a>
  );
}

export function ModuleCard({ module, index }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const { stats } = module;
  const isAvailable = module.availableOn ? dayjs().isAfter(module.availableOn) : true;
  const intl = useIntl();

  return (
    <div
      class={`border-t dark:border-gray-700 dark:text-gray-400 ${
        isAvailable ? '' : 'opacity-75 hover:opacity-100'
      }`}
    >
      <div
        class="flex items-center hover:bg-gray-50 dark:hover:bg-black dark:hover:bg-opacity-25 cursor-pointer px-6 py-4 gap-6"
        onClick={() => setIsOpen(!isOpen)}
      >
        <CalendarDate
          bg="bg-gray-50 dark:bg-gray-800 shadow-sm"
          date={module.availableOn}
          num={module.availableOn ? undefined : index + 1}
        />
        <div class="flex flex-col justify-center gap-1 py-1">
          <h2 class="text-base font-medium text-gray-900 dark:text-gray-300 line-clamp-2">
            {module.title}
          </h2>
          <dl class="flex flex-col md:flex-row gap-1 md:gap-4 text-xs dark:text-gray-300">
            {module.isDraft && <Pill color="green">Draft</Pill>}
            <dd class="flex items-center gap-1">
              <IcoBook />
              <span>
                {intl('{count:number} {count | pluralize lesson lessons}', {
                  count: module.lessons.length,
                })}
              </span>
            </dd>
            {isAvailable && module.completionRate !== undefined && (
              <dd class="flex items-center gap-1">
                <IcoChartPie />
                <span>
                  {intl('{percent:number}% completed', { percent: module.completionRate })}
                </span>
              </dd>
            )}
            {!!stats?.discussions && (
              <dd class="flex items-center gap-1 relative">
                <IcoChat />
                <span>
                  {intl('{count:number} {count | pluralize discussion discussions}', {
                    count: stats.discussions,
                  })}
                </span>
              </dd>
            )}
            {!!stats?.assignments && (
              <dd class="hidden md:flex items-center gap-1 relative">
                <IcoDocument />
                <span>
                  {intl('{count:number} {count | pluralize assignment assignments}', {
                    count: stats.assignments,
                  })}
                </span>
              </dd>
            )}
          </dl>
        </div>
      </div>
      <Case when={isOpen}>
        <div class="dark:text-gray-400 p-4 pt-0">
          {module.lessons.map((lesson) => (
            <LessonItem key={lesson.id} lesson={lesson} />
          ))}
        </div>
      </Case>
    </div>
  );
}
