import { showError } from '@components/app-error';
import { BtnPrimary, BtnSecondary, Button } from '@components/buttons';
import { CreateCourseModal } from '@components/create-course-modal';
import { StandardDialog } from '@components/dialog';
import { IcoCheckCircle } from '@components/icons';
import { useAuth } from 'client/lib/auth';
import { useState } from 'preact/hooks';

function Step({
  title,
  isComplete,
  href,
  onClick,
}: {
  title: string;
  isComplete?: boolean;
  href?: string;
  onClick?: () => void;
}) {
  return (
    <Button
      class={`px-2 py-4 gap-2 items-center flex border rounded-lg text-left xl:rounded-lg group bg-white shadow-sm ${
        onClick || href ? 'hover:bg-violet-50' : 'cursor-default'
      }`}
      href={href}
      onClick={onClick}
    >
      <IcoCheckCircle class={`size-7 ${isComplete ? 'text-green-500' : 'text-gray-300'}`} />
      <p
        class={`font-medium text-xs ${
          isComplete ? 'text-gray-500 line-through' : 'text-black font-medium'
        }`}
      >
        {title}
      </p>
    </Button>
  );
}

function GettingStartedVideo({
  isWatched,
  hide,
  onWatched,
}: {
  isWatched: boolean;
  hide: () => void;
  onWatched: () => void;
}) {
  return (
    <StandardDialog contentWidth onClose={hide}>
      <iframe
        src="https://player.vimeo.com/video/845861404?autoplay=1&h=4e222ae5de&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
        className="w-full h-48 lg:min-h-lg"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
        title="Ruzuku course setup"
      ></iframe>
      <footer class="flex mt-4 gap-4">
        {!isWatched && (
          <BtnPrimary class="rounded-full px-8" onClick={onWatched}>
            Mark as watched
          </BtnPrimary>
        )}
        <BtnSecondary class="rounded-full px-8" onClick={hide}>
          Close
        </BtnSecondary>
      </footer>
    </StandardDialog>
  );
}

export function GuideChecklist() {
  const auth = useAuth();
  const metadata = auth.user?.metadata;
  const [modal, setModal] = useState<'course' | 'video' | undefined>(undefined);
  const hasWatchedIntroVideo = !!metadata?.watchedIntroVideo;
  const isProfileComplete = !!metadata?.updatedProfile;
  const isTeachingCourse = !!metadata?.createdACourse;
  const completedStepsCount =
    [isProfileComplete, hasWatchedIntroVideo, isTeachingCourse].filter((step) => !!step).length + 1;

  return (
    <div class="bg-linear-to-b from-gray-100 to-indigo-50 p-4 sm:p-6 rounded-2xl">
      {modal === 'course' && <CreateCourseModal isOpen hide={() => setModal(undefined)} />}
      {modal === 'video' && (
        <GettingStartedVideo
          isWatched={hasWatchedIntroVideo}
          onWatched={async () => {
            try {
              await auth.updateUserMetadata({
                watchedIntroVideo: true,
              });
            } catch (err) {
              showError(err);
            } finally {
              setModal(undefined);
            }
          }}
          hide={() => setModal(undefined)}
        />
      )}
      <div class="flex justify-between mb-4 sm:mb-6 items-center">
        <p class="text-black text-lg xl:text-2xl font-bold">Getting started</p>
        <div class="text-sm font-medium text-indigo-600">{completedStepsCount} of 4 complete!</div>
      </div>
      <div class="grid grid-cols-1 xl:grid-cols-2 gap-4">
        <Step title="1. Create your account" isComplete />
        <Step title="2. Update your photo and bio" isComplete={isProfileComplete} href="/account" />
        <Step
          title="3. Watch getting started video"
          isComplete={hasWatchedIntroVideo}
          onClick={() => setModal('video')}
        />
        <Step
          title="4. Create your first course"
          isComplete={isTeachingCourse}
          onClick={() => setModal('course')}
        />
      </div>
    </div>
  );
}
