/**
 * User avatar icons
 * Will present placeholder initials if no icon specified
 */
import { Button } from '@components/buttons';
import { useAuth, useCurrentUser } from '@components/router/session-context';
import { showToast } from '@components/toaster';
import { filepicker } from 'client/components/filepicker';
import { rpx } from 'client/lib/rpx-client';
import { useImageUrl } from 'client/utils/cdn';
import { UserProfileRow } from 'server/types';
import { bgcolor, generateNameInitials } from './utils';

interface Props {
  user: Pick<UserProfileRow, 'name' | 'profilePhotoUrl'>;
  size?: string;
  maxWidth?: number;
}

/**
 * User profile picture - circular image or initials
 * Username will be converted to initials if an icon url is not provided,
 * otherwise a circular icon with size value corresponding to a Tailwinds CSS size
 * (see https://tailwindcss.com/docs/width | https://tailwindcss.com/docs/height)
 */
export function UserProfileIcon({ user, size = 'size-20', maxWidth = 256 }: Props) {
  const imageUrl = useImageUrl(user.profilePhotoUrl, {
    maxWidth,
  });
  const initials = !imageUrl ? generateNameInitials(user.name) : undefined;

  return (
    <span
      class={`${size} inline-flex rounded-full text-white items-center justify-center overflow-hidden shrink-0`}
      style={{ background: initials && bgcolor(user.name || '') }}
    >
      {!!imageUrl && <img class="object-cover" src={imageUrl} alt={initials} />}
      {!imageUrl && initials}
    </span>
  );
}

export function CurrentUserProfileIcon(props: Omit<Props, 'user'>) {
  const user = useCurrentUser();
  return user ? <UserProfileIcon user={user} {...props} /> : null;
}

export function UserProfileIconChanger(props: {
  size?: string;
  user: {
    id: UserProfileRow['userId'];
    profilePhotoUrl?: UserProfileRow['profilePhotoUrl'];
    name: UserProfileRow['name'];
  };
}) {
  const currentUser = useCurrentUser();
  const auth = useAuth();
  return (
    <Button
      type="button"
      class="cursor-pointer focus:outline-hidden focus:ring-2 focus:ring-indigo-600 hover:outline-hidden hover:ring-2 hover:ring-indigo-600 rounded-full leading-3 h-20 transition"
      onClick={async () => {
        try {
          const result = await filepicker({
            sources: ['filepicker', 'takephoto'],
            requireCrop: true,
            cropRatio: 1,
            accept: 'image/*',
          });
          if (!result?.fileId || !result?.filePath) {
            return;
          }
          await rpx.auth.updateCurrentUserProfilePhoto({
            profilePhotoUrl: result.filePath,
          });
          if (currentUser && currentUser.id === props.user.id) {
            auth.setUser({
              ...currentUser,
              profilePhotoUrl: result.filePath,
            });
          }
          showToast({
            type: 'ok',
            title: 'Upload complete',
            message: 'Profile image updated',
          });
        } catch {
          showToast({
            type: 'warn',
            title: 'Upload error',
            message: 'There was a problem uploading your profile image',
          });
        }
      }}
    >
      <UserProfileIcon {...props} />
    </Button>
  );
}
