/**
 * This file contains the logic for displaying the files that are attached to comments.
 * This rendering logic is very similar to the logic we use for rendering document attachments,
 * but *that* logic is raw-DOM and this is Preact. It might be a good idea to figure out
 * a way to unify them.
 */

import { IcoPaperclip, IcoZoomIn } from '@components/icons';
import { FileRec } from 'server/types';
import { useImageUrl } from 'client/utils/cdn';
import { isImage, isVideo, isAudio } from 'shared/media';
import { ManualDom } from '@components/manual-dom';
import { useMemo } from 'preact/hooks';
import { renderMediaPlayer, Props as MediaPlayerProps } from '@components/media-card/media-player';
import { useIntl } from 'shared/intl/use-intl';

interface Props {
  attachment: FileRec;
  fullSize?: boolean;
  allowFullScreen?: boolean;
  onClick?: () => void;
  class?: string;
}

type MediaProps = Pick<FileRec, 'url' | 'name' | 'poster'> & {
  fullSize?: boolean;
};

/**
 * Render the file attached to a comment.
 */
export function AttachmentContent({
  attachment,
  fullSize = false,
}: Pick<Props, 'attachment' | 'fullSize'>) {
  const attachmentUrl = useImageUrl(attachment.url);

  if (!attachmentUrl) {
    return null;
  }

  const props = {
    url: attachmentUrl,
    type: attachment.type,
    poster: attachment.poster,
    name: attachment.name,
    fullSize,
  };

  if (isImage(attachment.type)) {
    return <Image {...props} />;
  } else if (isVideo(attachment.type) || isAudio(attachment.type)) {
    return <MediaPlayer {...props} />;
  }
  return <UnknownFile {...props} />;
}

export function AttachmentItem(props: Props) {
  const { attachment, fullSize = false, allowFullScreen, onClick, class: className = '' } = props;
  const canExpand = allowFullScreen && isImage(attachment.type) && !fullSize && !!onClick;
  const attachmentUrl = useImageUrl(attachment.url);

  return (
    <div
      class={`attachment-item inline-flex justify-center items-center group relative grow ${
        canExpand ? 'cursor-zoom-in' : ''
      } ${fullSize ? 'h-full' : 'max-h-full'}  ${className}`}
      onClick={onClick}
    >
      <AttachmentContent {...props} />
      {attachmentUrl && fullSize && isImage(attachment.type) && (
        <a
          class="flex absolute top-2 right-2 bg-black/50 w-8 h-8 justify-center items-center rounded-sm"
          target="_blank"
          rel="noopener noreferrer"
          href={attachmentUrl}
        >
          <IcoZoomIn class="h-5 w-5 text-white" />
        </a>
      )}
    </div>
  );
}

function Image({ name, url, fullSize }: MediaProps) {
  let source = url;
  if (!fullSize) {
    source += '?width=800';
  }

  return (
    <img
      class={`${fullSize ? 'max-h-full' : 'rounded-2xl'} max-w-full max-h-(screen-56)`}
      alt={name}
      loading="lazy"
      src={source}
    />
  );
}

function MediaPlayer(props: MediaPlayerProps) {
  const poster = useImageUrl(props.poster);
  const el = useMemo(() => {
    return renderMediaPlayer({
      ...props,
      poster,
      isResolved: true,
      class: 'w-full max-w-full',
    });
  }, [props.url]);

  return <ManualDom el={el} class="w-full max-w-full max-h-(screen-56) rounded-2xl" />;
}

function UnknownFile({ name, url }: MediaProps) {
  const intl = useIntl();
  return (
    <a
      target="blank"
      href={url}
      class="bg-gray-100 hover:bg-gray-200 dark:hover:bg-gray-700/50 dark:bg-gray-700 p-2 grow block rounded-lg"
    >
      <IcoPaperclip class="w-4 h-4 mr-2 inline-block align-middle" />
      {name || intl('View unnamed file...')}
    </a>
  );
}
