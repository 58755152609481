import { showError } from '@components/app-error';
import { Case } from '@components/conditional';
import { IcoCheckCircle, IcoStripe } from '@components/icons';
import { LoadingIndicator } from '@components/loading-indicator';
import { router, useRouteParams } from '@components/router';
import { DefaultSpinner } from '@components/spinner';
import { rpx } from 'client/lib/rpx-client';
import { useAsyncEffect } from 'client/utils/use-async-effect';
import { useEffect, useState } from 'preact/hooks';
import { URLS } from 'shared/urls';
import { BtnStripeConnect } from '../pmts/components/stripe-connect';
import { IntegrationRow, IntegrationValueItem } from './integration-value-item';

const store = rpx.stripeConnect;

type Props = { stripeAcct?: string };

/**
 * If this page is being displayed as a return redirect from Stripe connect, it will look something
 * like this: /account/integrations/stripe?scope=read_write&code=ac_abc123
 * This component will render and finish the Stripe connect process.
 */
function FinishOauth({
  code,
  oauthState,
}: {
  code: string;
  oauthState: { course?: string; tenant: string };
}) {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function init() {
      try {
        await store.finishStripeConnect({ code });

        if (oauthState?.course) {
          router.goto(
            URLS.guide.course({
              courseId: oauthState.course,
            }),
          );
        } else {
          // Hide the Stripe OAuth URL params and redirect so we show the correct state
          router.rewrite('/account/integrations');
          location.reload();
        }
      } catch (err) {
        showError(err);
        setIsLoading(false);
      }
    }
    init();
  }, []);

  return (
    <>
      {isLoading && <LoadingIndicator />}
      <p>
        {isLoading
          ? 'Verifying Stripe account...'
          : `Your Stripe account is connected. You're ready to start taking payments!`}
      </p>
    </>
  );
}

function ConnectPage(props: Props) {
  const { allowchange } = useRouteParams();

  if (!allowchange && props.stripeAcct) {
    return (
      <div class="flex">
        <span>
          <IcoCheckCircle class="w-6 h-6 text-green-600 inline-block mr-2" />
        </span>
        <div class="grow">
          <p>
            Stripe is properly configured. If you would like to change Stripe accounts, please{' '}
            <a href="mailto:support@ruzuku.com">contact support</a>, and they will walk you through
            the process.
          </p>
          {props.stripeAcct && (
            <IntegrationValueItem title="Stripe Account" value={props.stripeAcct} />
          )}
        </div>
      </div>
    );
  }

  return (
    <div>
      <p class="mb-2">
        {props.stripeAcct && <IcoCheckCircle class="w-6 h-6 text-green-600 inline-block mr-2" />}
        {!!allowchange &&
          `Stripe is properly configured. You can change your Stripe account, but all of your existing price points will continue to use the old Stripe account.`}
        {!allowchange && `Connect with Stripe to start taking payments.`}
      </p>
      <BtnStripeConnect
        class={`${
          allowchange ? 'bg-red-800 hover:bg-red-600' : 'bg-indigo-600 hover:bg-indigo-500'
        } text-white px-4 py-1 inline-flex items-center justify-center rounded`}
      >
        <span class="flex items-center gap-2">
          <Case when={!!props.stripeAcct} fallback={'Connect with'}>
            <IcoCheckCircle class="w-6 h-6 mr-1" /> Integrated with{' '}
          </Case>
          <IcoStripe class="h-10 w-14" fill="white" />
        </span>
      </BtnStripeConnect>
    </div>
  );
}

export function StripeIntegration(props: Props) {
  const { integrationType, code, state } = useRouteParams();
  const oauthState = integrationType === 'stripe-oauth' && state ? JSON.parse(state) : undefined;

  return (
    <IntegrationRow icon={<IcoStripe />}>
      {oauthState && <FinishOauth code={code} oauthState={oauthState} />}
      {!oauthState && <ConnectPage {...props} />}
    </IntegrationRow>
  );
}

/**
 * Handle the Stripe oath redirect. This needs to be public, since the user
 * may not be authenticated in core Ruzuku (or even have a core Ruzuku account).
 * So, we reditect to the appropriate tenant, and then finish processing there.
 */
export function StripeOauthRedirect() {
  const { state } = useRouteParams();
  const { tenant } = JSON.parse(state);
  useAsyncEffect(async () => {
    const { domain } = await store.getTenantDomain({ tenantId: tenant });
    const port = location.port ? `:${location.port}` : '';
    // Redirect to the tenant, and the oauth-finalization route
    location.assign(
      `${location.protocol}//${domain}${port}/account/integrations/stripe-oauth${location.search}`,
    );
  }, []);

  return (
    <div class="text-center p-8">
      <DefaultSpinner />
    </div>
  );
}
