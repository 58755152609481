/**
 * This layout is complex, and can probably be simplified.
 * When modifying these screens, there are many edge cases
 * to consider and test:
 *
 * - Pinned and unpinned side-nav
 * - Courses with and without banner images
 * - Screens of various sizes
 * - All of the student-facing course screens
 *
 * That list seems small, but the permutations and combinations
 * are tricky, and often lead to weird edge cases and incorrect
 * spacing, so test thoroughly and proceed with caution!
 */

import { IcoRuzukuLogo } from '@components/icons';
import { useCurrentTenant } from '@components/router/session-context';
import { StudentThemeProvider } from '@components/student-theme-picker';
import { useDocumentTitle } from 'client/utils/use-document-title';
import { BtnManageCourse } from './btn-manage-course';
import { CourseNavLinks } from './course-nav-links';
import { PageHeader } from './page-header';
import { Props, StudentTabs } from './props';
import { SideNavProvider, StudentSideNav } from './student-side-nav';
import { intl } from 'shared/intl/use-intl';

export function PoweredByRuzuku() {
  const tenant = useCurrentTenant();

  if (!tenant.isCore) {
    return null;
  }

  return (
    <footer class="absolute bottom-4 right-4">
      <a
        href="https://www.ruzuku.com/?utm_source=student&utm_content=powered-by-ruzuku&utm_medium=link"
        class="flex flex-col text-gray-500 dark:text-gray-500 leading-tight"
      >
        <span>powered by</span>
        <IcoRuzukuLogo class="fill-current h-8" />
      </a>
    </footer>
  );
}

const PAGE_TITLES: Record<StudentTabs, string | undefined> = {
  home: intl('Overview'),
  calendar: intl('Calendar'),
  discussions: intl('Discussions'),
  meetings: intl('Meetings'),
  preferences: intl('Course Preferences'),
  modules: undefined,
  people: undefined,
};

export function StudentPage({
  children,
  course,
  currentLink,
  sideNavContent,
  sidePaneContent,
  accessLevel = 'student',
  isPinnable = false,
  hiddenSideNavContent,
  editLink = {},
  p = 'py-12 xl:py-20',
  documentTitle,
}: Props) {
  useDocumentTitle([documentTitle, PAGE_TITLES[currentLink], course.title]);

  return (
    <StudentThemeProvider>
      <SideNavProvider isPinnable={isPinnable} storageKey="studentPinSideNav">
        <div class="flex">
          <nav class="hidden lg:flex lg:sticky top-0 h-screen relative z-40">
            <CourseNavLinks
              isThemable
              course={course}
              currentLink={currentLink}
              accessLevel={accessLevel}
            />
          </nav>
          <div class="relative flex grow flex-col min-h-screen bg-white dark:bg-gray-900 student-page">
            <PageHeader
              course={course}
              accessLevel={accessLevel}
              manageButton={
                editLink && (
                  <BtnManageCourse
                    class="hidden md:inline-flex"
                    course={course}
                    url={editLink.url}
                  />
                )
              }
            />
            <BtnManageCourse
              class="inline-flex md:hidden mx-4 mt-8"
              course={course}
              url={editLink.url}
            />
            <div class="relative flex grow">
              <div class={sideNavContent ? '' : 'lg:hidden'}>
                <StudentSideNav
                  course={course}
                  currentLink={currentLink}
                  hiddenSideNavContent={hiddenSideNavContent}
                  accessLevel={accessLevel}
                >
                  {sideNavContent}
                </StudentSideNav>
              </div>
              <div class="flex grow flex-col lg:flex-row">
                <div class={`relative flex grow max-w-full dark:bg-transparent ${p}`}>
                  {children}
                </div>
                {sidePaneContent}

                <PoweredByRuzuku />
              </div>
            </div>
          </div>
        </div>
      </SideNavProvider>
    </StudentThemeProvider>
  );
}
