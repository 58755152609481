import { IcoInfo } from '@components/icons';
import { ComponentChildren } from 'preact';

interface Props {
  children?: ComponentChildren;
  icon?: ComponentChildren;
}

export function ToolTip(props: Props) {
  return (
    <div class="group relative">
      <span class="hidden group-hover:block absolute rounded-sm shadow-lg px-4 py-2 bg-black bg-opacity-75 text-sm text-white text-center top-6 w-64 -left-28">
        {props.children}
      </span>
      {props.icon || <IcoInfo class="text-gray-500 size-5" />}
    </div>
  );
}

interface TooltippedItemProps {
  tooltip: ComponentChildren;
  tooltipPosition?: string;
  children: ComponentChildren;
}
/** Displays an item wrapped with a hover tooltip */
export function TooltippedItem(props: TooltippedItemProps) {
  const { tooltipPosition = 'left-full -translate-x-1/2 -top-10' } = props;
  return (
    <span class="relative group">
      <span
        class={`absolute tooltip bg-black bg-opacity-75 px-2 py-1 z-20 text-white rounded-sm text-sm ${tooltipPosition} cursor-pointer invisible group-hover:visible w-96`}
      >
        {props.tooltip}
      </span>
      {props.children}
    </span>
  );
}
