import { ComponentChildren } from 'preact';
import { rpx } from 'client/lib/rpx-client';
import { BtnCopy, BtnPrimary, Button } from '@components/buttons';
import { AsyncForm, FormSubSection, FormGroupTwoCol } from '@components/async-form';
import { showToast } from '@components/toaster';
import { Dispatch, StateUpdater, useEffect, useState } from 'preact/hooks';
import { filepicker } from 'client/components/filepicker';
import { showError } from '@components/app-error';
import { Spinner } from '@components/spinner';
import { useImageUrl } from 'client/utils/cdn';
import { AccountTabContent } from '../account-tab-content';
import { useLocalStorageState } from 'client/lib/hooks';
import { IcoCheckCircle, IcoPencil } from '@components/icons';
import { autoFocusSelf } from 'client/utils/autofocus';
import { CORE_DOMAIN } from 'shared/consts';

interface NewTenantWizardState {
  step: number;
  domain: string;
  iconUrl: string;
  name: string;
}

function goToNextStep(setState: Dispatch<StateUpdater<NewTenantWizardState>>) {
  setState((s) => ({ ...s, step: s.step + 1 }));
}

/**
 * The current user hasn't set up their tenant yet. We'll walk them
 * through their domain registration and configuration first, then we'll
 * move on to the tenant setup.
 */
export function TenantSetupWizard() {
  const [state, setState] = useLocalStorageState<NewTenantWizardState>('newTenantWizard', {
    step: 1,
    domain: '',
    iconUrl: '',
    name: '',
  });

  const onStepChange = (step: number) => setState((s) => ({ ...s, step }));

  return (
    <AccountTabContent title="Set up your Ruzuku Pro site">
      <div class="flex flex-col gap-6">
        <header class="opacity-75">Configure your site in 4 easy steps.</header>
        <div class="divide-y flex flex-col border rounded-md">
          <DomainSetupBullet
            num={1}
            currentNum={state.step}
            title="Choose a domain for your courses"
            onStepChange={onStepChange}
          >
            <p>
              Make sure you own a domain (such as <ExampleText>example.com</ExampleText>). If you
              don't own a domain, you can purchase one at a domain registar such as{' '}
              <a href="https://www.dreamhost.com/domains/">DreamHost</a>.
            </p>
            <footer>
              <BtnPrimary onClick={() => goToNextStep(setState)}>
                I have purchased my domain ➜
              </BtnPrimary>
            </footer>
          </DomainSetupBullet>
          <DomainSetupBullet
            num={2}
            currentNum={state.step}
            title="Point your subdomain to Ruzuku"
            onStepChange={onStepChange}
          >
            <p>
              Next, you need to set up a subdomain. This can be done in your domain registrar (where
              you purchased your domain). The subdomain should be a CNAME record that points to
              <BtnCopy value={CORE_DOMAIN} class="bg-gray-200 rounded-md">
                {CORE_DOMAIN}
              </BtnCopy>
              .
            </p>
            <p>
              For example, if your domain is <ExampleText>example.com</ExampleText>, you might
              create a subdomain like <ExampleText>courses.example.com</ExampleText>. For more
              details,{' '}
              <a
                href="https://support.ruzuku.com/article/820-setting-up-your-pro-custom-domain"
                target="_blank"
                rel="noopener noreferrer"
              >
                see our documentation
              </a>{' '}
              on how to set up your pro site (your custom domain).
            </p>
            <footer>
              <BtnPrimary onClick={() => goToNextStep(setState)}>
                I have configured my subdomain ➜
              </BtnPrimary>
            </footer>
          </DomainSetupBullet>
          <DomainSetupBullet
            num={3}
            currentNum={state.step}
            title="Verify that your subdomain works with Ruzuku"
            onStepChange={onStepChange}
          >
            <SubdomainVerification state={state} setState={setState} />
          </DomainSetupBullet>
          <DomainSetupBullet
            num={4}
            currentNum={state.step}
            title="Create your site"
            onStepChange={onStepChange}
          >
            <NewTenantForm state={state} setState={setState} />
          </DomainSetupBullet>
        </div>
      </div>
    </AccountTabContent>
  );
}

function DomainSetupBullet(props: {
  num: number;
  currentNum: number;
  title: string;
  onStepChange(step: number): void;
  children: ComponentChildren;
}) {
  const collapsed = props.num !== props.currentNum;
  const completed = props.num < props.currentNum;

  return (
    <div class="flex p-4">
      <span class="font-bold text-xl opacity-75 min-w-8 pl-1">{props.num}.</span>
      <div class="p-1 flex flex-col gap-1">
        <Button
          class="font-semibold flex items-center gap-2 cursor-pointer"
          onClick={() => props.num < props.currentNum && props.onStepChange(props.num)}
        >
          {completed && (
            <span class="text-green-600">
              <IcoCheckCircle />
            </span>
          )}
          {props.title}
        </Button>
        {!collapsed && <div class="flex flex-col gap-4 mb-2">{props.children}</div>}
      </div>
    </div>
  );
}

function ExampleText(props: { children: ComponentChildren }) {
  return <span class="inline-block px-1 bg-gray-200 rounded-md">{props.children}</span>;
}

function SubdomainVerification({
  state,
  setState,
}: {
  state: NewTenantWizardState;
  setState: Dispatch<StateUpdater<NewTenantWizardState>>;
}) {
  const [isProcessing, setIsProcessing] = useState(false);
  const [isPolling, setIsPolling] = useState(false);

  const validateDomain = async () => {
    try {
      setIsProcessing(true);
      const { isValid } = await rpx.tenants.validateDomain({ domain: state.domain });
      if (isValid) {
        goToNextStep(setState);
        return true;
      } else {
        setIsPolling(true);
      }
    } catch (err) {
      setIsPolling(false);
      showError(err);
    } finally {
      setIsProcessing(false);
    }
    return false;
  };

  useEffect(() => {
    const interval = 60 * 1000; // We'll check the domain every 60 seconds.
    let timeout: any;

    const poll = () => {
      validateDomain().then((isValid) => {
        if (!isValid) {
          timeout = setTimeout(poll, interval);
        }
      });
    };

    if (isPolling) {
      timeout = setTimeout(poll, interval);
    }

    return () => clearTimeout(timeout);
  }, [isPolling]);

  if (isPolling) {
    return (
      <div class="gap-4 flex flex-col mt-4">
        <p class="flex items-center gap-2">
          <Spinner class="border-indigo-600" /> <span>Verifying</span>
          <Button
            class="inline-flex items-center gap-2 bg-gray-200 hover:bg-gray-300 rounded-md px-2 align-middle"
            onClick={() => setIsPolling(false)}
          >
            <span>
              <IcoPencil />
            </span>
            {state.domain}
          </Button>{' '}
        </p>
        <p>
          We could not verify your subdomain, but will continue attempting to verify it. Make sure
          your subdomain is a CNAME record that points to{' '}
          <BtnCopy value={CORE_DOMAIN} class="bg-gray-200 rounded-md" margin=" ">
            {CORE_DOMAIN}
          </BtnCopy>
          .
        </p>
        <p>
          Note: It can take up to an hour for your domain settings to propagate. You may want to
          check back a little later. If you continue to have trouble verifying your subdomain,{' '}
          <a href={`mailto:support@ruzuku.com`}>contact Ruzuku support</a>.
        </p>
      </div>
    );
  }

  return (
    <AsyncForm class="flex flex-col gap-4" onSubmit={validateDomain}>
      <label>
        <span class="block mb-1">
          Enter your subdomain in the field below, and we'll verify that it is properly configured.
        </span>
        <input
          type="text"
          name="domain"
          required
          value={state.domain}
          onInput={(e: any) => setState((s) => ({ ...s, domain: e.target.value }))}
          ref={autoFocusSelf}
          pattern="[^/]*"
          placeholder="courses.example.com"
          title="Enter a domain (with no slashes /)"
          class="ruz-input"
        />
      </label>

      <footer class="flex gap-4">
        <BtnPrimary isLoading={isProcessing}>Verify my subdomain ➜</BtnPrimary>
      </footer>
    </AsyncForm>
  );
}

function NewTenantForm(props: {
  state: NewTenantWizardState;
  setState: Dispatch<StateUpdater<NewTenantWizardState>>;
}) {
  const [isProcessing, setIsProcessing] = useState(false);
  const [iconUrl, setIconUrl] = useState<undefined | string>(undefined);
  const fullIconUrl = useImageUrl(iconUrl);

  const pickLogo = async () => {
    try {
      setIsProcessing(true);
      const result = await filepicker({
        accept: 'image/*',
        sources: ['filepicker'],
      });
      if (result) {
        setIconUrl(result.publicUrl);
      }
    } catch (err) {
      showError(err);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <AsyncForm
      onSubmit={async (data) => {
        const tenantInfo = {
          ...data,
          domain: props.state.domain,
          iconUrl,
        };
        await rpx.tenants.createTenant(tenantInfo);
        showToast({
          type: 'ok',
          title: 'Site created!',
          message: 'Your site has been created.',
        });

        // Reload the page so the live tenant info takes effect
        location.replace('/account/site');

        // Wait forever so the from doesn't get double-submitted during reload
        return new Promise(() => {});
      }}
    >
      <p class="text-gray-500">Customize your Ruzuku Pro site.</p>
      <FormSubSection class="text-gray-500">
        <FormGroupTwoCol prop="domain" labelText="Subdomain">
          <span class="border border-green-100 bg-green-50 rounded-md text-green-600 inline-flex items-center gap-2 p-1 px-2">
            <IcoCheckCircle />
            {props.state.domain}
          </span>
        </FormGroupTwoCol>
        <FormGroupTwoCol prop="logo" labelText="Logo" subText="The logo to be used for branding.">
          <Button
            type="button"
            class="cursor-pointer focus:outline-hidden focus:ring-2 focus:ring-indigo-600 w-24 h-24 bg-gray-200 inline-flex items-center justify-center rounded-xs"
            onClick={pickLogo}
          >
            {!!fullIconUrl && <img src={fullIconUrl} class="w-full" />}
            {!fullIconUrl && '...'}
          </Button>
        </FormGroupTwoCol>
        <FormGroupTwoCol
          prop="name"
          labelText="Name"
          subText="The brand name to be displayed in emails and site headers"
        >
          <input type="text" name="name" placeholder="Site name" class="ruz-input" />
        </FormGroupTwoCol>
      </FormSubSection>

      <footer class="mt-6">
        <BtnPrimary isLoading={isProcessing}>
          Activate Ruzuku Pro on {props.state.domain} ➜
        </BtnPrimary>
      </footer>
    </AsyncForm>
  );
}
