import { showError } from '@components/app-error';
import { useBasicAutosaver } from '@components/autosaver';
import { BtnPreWarning, BtnSecondary, Button } from '@components/buttons';
import { replacements, templates } from '@components/certificate';
import { ColorPicker } from '@components/color-picker';
import { showDialog } from '@components/dialog';
import { Dropdown, MenuItem } from '@components/dropdown';
import { EmptyGuidePage } from '@components/empty-page';
import { GuideCoursePage } from '@components/guide-course-page';
import { IcoBadge, IcoCheckCircle, IcoDB, IcoDotsVertical, IcoRefresh } from '@components/icons';
import { ImgPicker } from '@components/img-picker';
import { PageNavBar, PageNavBody } from '@components/page-nav-bar';
import { RouteProps, router } from '@components/router';
import { useCurrentTenant, useCurrentUser } from '@components/router/session-context';
import { Toggle } from '@components/toggle';
import { AppRoute } from 'client/lib/app-route/types';
import { rpx } from 'client/lib/rpx-client';
import { ComponentChildren } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import { CertificateFields, CertificateRow } from 'server/types';
import { URLS } from 'shared/urls';

type Data = Awaited<ReturnType<typeof load>>;

function makeDefaultCert(courseId: UUID) {
  const defaultCertificate: Omit<CertificateRow, 'id'> = {
    courseId,
    customizedText: defaultTemplate,
    showBadge: true,
    template: 'modern',
    isEnabled: false,
    updatedAt: new Date(),
    createdAt: new Date(),
  };
  return defaultCertificate;
}

const saveCertificate = async (cert: Omit<CertificateRow, 'id'> & { id?: string }) => {
  const { id, updatedAt, createdAt, ...rest } = cert;
  await rpx.certificates.saveCertificate({
    ...rest,
    accentColor: rest.accentColor || null,
  });
  return cert;
};

const defaultTemplate: CertificateFields = {
  title: 'Certificate of Completion',
  certifies: 'Certifies that {{STUDENT_NAME}} completed',
  subtitle: '{{COURSE_TITLE}}',
  description: 'On the date {{ISSUED_DATE}}, {{STUDENT_NAME}} completed {{COURSE_TITLE}}.',
  issuer: 'Issuer {{GUIDE_NAME}}',
  issued: 'Issued {{ISSUED_DATE}}',
};

function SectionHeading({ children }: { children: ComponentChildren }) {
  return <h2 class="font-semibold">{children}</h2>;
}

const triggerClass =
  'w-full border border-gray-300 p-2 px-3 gap-3 font-medium hover:bg-gray-50 text-inherit focus-within:outline focus-within:outline-2 outline-offset-2 focus-within:outline-indigo-500';

function Editor({ state, setState }: RouteProps<Data>) {
  const { course, completionDate } = state;
  const certificate = state.certificate!;
  const student = { name: 'Jane Doe' };
  const templateName = certificate.template;
  const [customFieldCopied, setCustomFieldCopied] = useState('');
  const issuedDate = completionDate;
  const template = templates.find((x) => x.name === templateName)!;
  const autosave = useBasicAutosaver(state.certificate, saveCertificate);
  const setCert = (fn: (cert: Data['certificate']) => Data['certificate']) => {
    setState((s) => ({ ...s, certificate: fn(s.certificate) }));
  };

  const resetCertificate = async () => {
    try {
      const ok = await showDialog({
        mode: 'warn',
        title: 'Lose all of your changes?',
        children: `If you reset the certificate, all of your changes will be lost, and you'll be returned to the original default template.`,
        confirmButtonText: 'Reset my changes',
        cancelButtonText: 'Keep my changes',
      });
      if (!ok) {
        return;
      }
      const cert = makeDefaultCert(state.course.id);
      await saveCertificate(cert);
      setState((s) => ({ ...s, certificate: cert }));
    } catch (err) {
      showError(err);
    }
  };

  useEffect(() => {
    if (customFieldCopied) {
      const timeout = setTimeout(() => setCustomFieldCopied(''), 5000);
      return () => clearTimeout(timeout);
    }
  }, [customFieldCopied]);

  return (
    <>
      <PageNavBar title="Certificate" interactive>
        <div class="absolute top-5 right-3 flex items-center gap-4">
          <span class="text-gray-500 inline-flex items-center gap-1">
            {autosave.isDirty ? <IcoRefresh /> : <IcoCheckCircle />}
            <span>{autosave.isDirty ? 'saving...' : 'saved'}</span>
          </span>
          <Dropdown
            hideDownIcon
            noPadding
            renderMenu={() => (
              <div class="flex flex-col p-2">
                <BtnPreWarning onClick={resetCertificate}>Reset Certificate</BtnPreWarning>
              </div>
            )}
          >
            <IcoDotsVertical />
          </Dropdown>
        </div>
        <PageNavBody class="gap-6 pb-40">
          <header>
            <label class="flex items-center gap-3 cursor-pointer mb-4">
              <Toggle
                checked={certificate.isEnabled}
                onClick={() => setCert((c) => ({ ...c, isEnabled: !c.isEnabled }))}
              />
              <span>Enable certificate</span>
            </label>
          </header>
          <header class="flex flex-col gap-2">
            <SectionHeading>Style</SectionHeading>
            <Dropdown
              triggerClass={triggerClass}
              renderMenu={() => {
                return (
                  <div class="flex flex-col gap-2 p-2 pb-0">
                    {templates.map((x) => (
                      <Button
                        key={x.name}
                        class="flex gap-4 rounded-sm p-2 font-semibold hover:bg-gray-50"
                        onClick={() => setCert((c) => ({ ...c, template: x.name }))}
                      >
                        {x.Thumbnail()}
                        <span class="capitalize">{x.name}</span>
                      </Button>
                    ))}
                  </div>
                );
              }}
            >
              <span class="flex w-full gap-3">
                {template.Thumbnail()}
                <span class="capitalize">{template.name}</span>
              </span>
            </Dropdown>
          </header>
          <header class="flex flex-col gap-2">
            <SectionHeading>Accent Color</SectionHeading>
            <section class="flex justify-between">
              <ColorPicker
                class={`${triggerClass} ${certificate.accentColor ? 'rounded-r-none' : ''}`}
                name="accentColor"
                onPick={(accentColor) => setCert((c) => ({ ...c, accentColor }))}
                color={certificate.accentColor}
              >
                <span class="font-medium">{certificate.accentColor || 'Default color'}</span>
              </ColorPicker>
              {certificate.accentColor && (
                <BtnSecondary
                  class="rounded-l-none bg-gray-50"
                  onClick={() => setCert((c) => ({ ...c, accentColor: undefined }))}
                >
                  Reset
                </BtnSecondary>
              )}
            </section>
          </header>
          <header class="flex flex-col gap-2 relative">
            <SectionHeading>Custom Fields</SectionHeading>
            {customFieldCopied && (
              <div class="absolute an-slide-up flex justify-center w-full z-20">
                <div class="bg-green-500 font-semibold text-white p-1 px-2 rounded-sm shadow-sm -mt-4">
                  Copied custom field to clipboard. Paste it anywhere in the certificate text.
                </div>
              </div>
            )}
            <Dropdown
              position="left-0 bottom-10"
              triggerClass={triggerClass}
              renderMenu={() => (
                <div class="p-2 space-y-2 flex flex-col">
                  <header class="p-4 py-2">
                    <strong>Insert Custom Field</strong>
                  </header>
                  {Object.keys(replacements).map((k) => (
                    <MenuItem
                      key={k}
                      onClick={() => {
                        const value = `{{${k}}}`;
                        navigator.clipboard.writeText(value);
                        setCustomFieldCopied(value);
                      }}
                    >
                      {`{{${k}}}`}
                    </MenuItem>
                  ))}
                </div>
              )}
            >
              <span class="grow flex items-center gap-3">
                <IcoDB />
                <span>Insert Custom Field</span>
              </span>
            </Dropdown>
          </header>
          <header class="flex flex-col gap-2">
            <SectionHeading>Badge</SectionHeading>
            <label class="flex items-center gap-3 cursor-pointer mb-4">
              <Toggle
                checked={certificate.showBadge}
                onClick={() => setCert((c) => ({ ...c, showBadge: !c.showBadge }))}
              />
              <span>Show badge</span>
            </label>
            {certificate.showBadge && (
              <>
                <ImgPicker
                  cropRatio={1}
                  placeholder="Upload a badge..."
                  src={certificate.badgeSrc}
                  onPick={({ publicUrl }) => setCert((c) => ({ ...c, badgeSrc: publicUrl }))}
                />
                {certificate.badgeSrc && (
                  <BtnPreWarning onClick={() => setCert((c) => ({ ...c, badgeSrc: '' }))}>
                    Delete Image
                  </BtnPreWarning>
                )}
              </>
            )}
          </header>
        </PageNavBody>
      </PageNavBar>
      <div class="p-2 sm:p-10 flex flex-col items-center grow overflow-auto relative">
        {template.Render({
          fields: certificate.customizedText,
          showBadge: certificate.showBadge,
          badgeSrc: certificate.badgeSrc,
          accentColor: certificate.accentColor,
          data: { course, issuedDate, student },
          onChange: (f) => setCert((c) => ({ ...c, customizedText: f(c.customizedText) })),
        })}
      </div>
    </>
  );
}

function Page(props: RouteProps<Data>) {
  const {
    setState,
    state: { hasCert, certificate, course },
  } = props;
  const terminology = useCurrentTenant().terminology;
  const currentUser = useCurrentUser()!;

  return (
    <GuideCoursePage
      course={course}
      viewLink={URLS.student.certificate({ course, userId: currentUser.id })}
      type="certificate"
    >
      {hasCert && <Editor {...props} />}
      {!hasCert && (
        <EmptyGuidePage
          Ico={(props) => <IcoBadge {...props} class={`${props.class || ''} fill-indigo-400`} />}
          onClick={async () => {
            try {
              await saveCertificate(certificate);
              setState((s) => ({ ...s, hasCert: true }));
            } catch (err) {
              showError(err);
            }
          }}
          title="Certificate"
          description={`Give students a certificate when they finish all ${terminology.lessons}.`}
          actionText="Create a Certificate of Completion"
        />
      )}
    </GuideCoursePage>
  );
}

async function load(route: AppRoute) {
  const { courseId } = route.params;
  const [course, certificate] = await Promise.all([
    rpx.courses.getGuideCourse({ id: courseId }),
    rpx.certificates.getCertificateForEdit({ courseId }),
  ]);
  return {
    course,
    completionDate: new Date(),
    certificate: certificate || makeDefaultCert(course.id),
    hasCert: !!certificate,
  };
}

router.add({
  url: `manage/courses/:courseId/certificate`,
  load,
  render: Page,
  authLevel: 'guide',
});
