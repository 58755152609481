import { showModalForm } from '@components/modal-form';
import { Currency, FullCourse } from 'server/types';
import { StudentUpsell, Offer } from './types';
import { RpxResponse, rpx } from 'client/lib/rpx-client';
import { ComponentChildren } from 'preact';
import { IcoArrowLeft, IcoCart, IcoCheckCircle } from '@components/icons';
import { useState } from 'preact/hooks';
import { ReadonlyMinidoc } from '@components/minidoc/readonly-minidoc';
import { ProductAndPricePreview } from './product-price-preview';
import { BtnPrimary, Button } from '@components/buttons';
import { CourseLogo } from '@components/course-image';
import { PriceSummary } from '@components/checkout';
import { Toggle } from '@components/toggle';
import * as fmt from 'shared/payments/fmt';
import * as pmtmath from 'shared/payments/math';
import { useEsc } from 'client/utils/use-esc';
import { Spinner } from '@components/spinner';
import { useAsyncData } from 'client/lib/hooks';
import { showError } from '@components/app-error';
import { useBodyScrollLock } from 'client/lib/hooks/use-body-scroll-lock';

type CheckoutState = RpxResponse<typeof rpx.courseCheckout.getCourseCheckoutState>;

type ModalMode =
  | 'wizard' // The student is being shown offers one at a time
  | 'totals' // The totals / confirm checkout screen
  | 'checkout'; // Perform checkout

type ModalOptions = {
  isPreview?: boolean;
  course: Pick<FullCourse, 'id' | 'title' | 'imagePath' | 'productId'>;
  price: NonNullable<Offer['price']>;
  coupon: CheckoutState['coupon'];
  upsell: StudentUpsell;
  cartItems?: string[];
  purchase(price: Offer['price'], coupon?: ModalOptions['coupon']): Promise<boolean>;
};

type ModalResult = {
  ok: boolean;
  cartItems: string[];
};

function UpsellModalWrapper({
  children,
  pageId,
  onClose,
}: {
  children: ComponentChildren;
  pageId: string;
  onClose?(): void;
}) {
  useBodyScrollLock(true);

  return (
    <section
      class="fixed w-screen h-screen overflow-auto inset-0 z-50 flex items-center justify-center bg-gray-800/50 backdrop-blur-sm"
      onMouseDown={(e) => {
        if (e.target === e.currentTarget) {
          e.preventDefault();
          e.stopPropagation();
          onClose?.();
        }
      }}
    >
      <div class="bg-white rounded-t-3xl sm:rounded-3xl shadow-2xl pt-8 p-10 flex overflow-x-hidden sm:w-(screen-16) max-h-(screen-16) max-w-2xl absolute bottom-0 inset-x-0 sm:static">
        <section class="grow flex flex-col gap-10 an-fade-in-left max-w-full" key={pageId}>
          {children}
        </section>
      </div>
    </section>
  );
}

function ModalHeader(props: { cartSize: number; title: string; gotoTotals(): void }) {
  return (
    <header class="flex justify-between sticky top-0 bg-white z-10">
      <h1 class="text-center font-semibold">{props.title}</h1>
      <button class="flex items-center gap-2 tex-xs" onClick={props.gotoTotals}>
        <IcoCart /> {props.cartSize}
      </button>
    </header>
  );
}

function OfferForm({
  offer,
  onAccept,
  onReject,
}: {
  offer: Offer;
  onAccept(): void;
  onReject(): void;
}) {
  return (
    <>
      <div class="flex flex-col gap-6">
        {offer.content && <ReadonlyMinidoc content={offer.content} />}
        <span class="flex items-center border rounded-2xl p-4">
          <ProductAndPricePreview
            product={offer.product}
            price={offer.price}
            listPrice={offer.listPrice}
          />
        </span>
      </div>
      <footer class="flex flex-col gap-4 sticky bottom-0 bg-white pt-2">
        <div class="absolute -top-2 inset-x-0 h-2 bg-linear-to-b from-transparent to-white"></div>
        <BtnPrimary class="rounded-full gap-2 pl-6 pr-4" onClick={onAccept}>
          <span>{offer.acceptText || 'Yes. Add to cart.'}</span>
          <IcoArrowLeft class="rotate-180" />
        </BtnPrimary>
        <button class="text-indigo-600 rounded-full" onClick={onReject}>
          {offer.rejectText || 'No, thanks.'}
        </button>
      </footer>
    </>
  );
}

function TotalsForm(
  props: ModalOptions & {
    gotoCheckout(): void;
    cartItems: string[];
    toggleCartItem(item: Offer): void;
  },
) {
  const dueToday = props.cartItems.reduce<{ amount: number; currency: Currency }>(
    (acc, itemId) => {
      const item = props.upsell.offers.find((o) => o.priceId === itemId)!;
      acc.amount += pmtmath.initialPrice(item);
      acc.currency = item.price.currency;
      return acc;
    },
    { amount: pmtmath.initialPrice(props), currency: 'USD' },
  );
  return (
    <section class="flex flex-col gap-10">
      <div class="flex flex-col gap-6">
        <span class="flex flex-col">
          <span class="inline-flex items-center gap-4 min-w-96 group relative">
            <CourseLogo image={props.course.imagePath} />
            <span class="flex flex-col items-start">
              <span class="font-medium">{props.course.title}</span>
              <span class="text-gray-600">
                <PriceSummary price={props.price} coupon={props.coupon} />
              </span>
            </span>
          </span>
        </span>

        {props.upsell.offers.map((offer) => {
          return (
            <span class="flex flex-col" key={offer.productId}>
              <ProductAndPricePreview
                product={offer.product}
                price={offer.price}
                listPrice={offer.listPrice}
                key={offer.productId}
              >
                <label class="ml-auto pl-4">
                  <Toggle
                    checked={props.cartItems.includes(offer.priceId)}
                    onClick={() => props.toggleCartItem(offer)}
                  />
                </label>
              </ProductAndPricePreview>
            </span>
          );
        })}
        <span class="border rounded-xl bg-green-50 border-green-200 text-green-800 inline-flex items-center gap-4 min-w-96 pr-4 group relative w-full">
          <span class="flex w-14 aspect-square rounded-l-xl bg-linear-to-r from-green-400 to-green-500 text-white items-center justify-center text-2xl -my-px -ml-px">
            {fmt.currencySymbol(dueToday.currency)}
          </span>
          <span class="flex flex-col font-semibold">
            <span class="text-sm text-green-600">Due Today</span>
            <span class="text-base leading-5">
              {fmt.price({ priceInCents: dueToday.amount, currency: dueToday.currency })}
            </span>
          </span>
        </span>
      </div>
      <footer class="flex flex-col gap-4 sticky bottom-0 bg-white pb-8">
        <div class="absolute -top-4 inset-x-0 h-4 bg-linear-to-b from-transparent to-white"></div>
        <BtnPrimary
          class="rounded-full gap-2 pl-6 pr-4 bg-green-500 hover:bg-green-600 font-semibold focus:ring-green-500"
          onClick={props.gotoCheckout}
        >
          <span>Finish checkout</span>
          <IcoArrowLeft class="rotate-180" />
        </BtnPrimary>
      </footer>
    </section>
  );
}

function CheckoutForm(
  props: ModalOptions & {
    cartItems: string[];
    onComplete(): void;
    onError(err?: any): void;
  },
) {
  const [progress, setProgress] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [failedOfferId, setFailedOfferId] = useState('');

  useAsyncData(async () => {
    let currentId = '';
    if (failedOfferId) {
      return;
    }
    try {
      const numItems = props.cartItems.length + 1;
      const steps = Math.round(100 / numItems);
      const runProgress = async () => {
        for (let i = 0; i < steps; ++i) {
          setProgress((x) => Math.min(100, x + 1));
          await new Promise((r) => setTimeout(r, 50));
        }
      };
      const purchaseProgress: ModalOptions['purchase'] = async (price, coupon) => {
        const [result] = await Promise.all([props.purchase(price, coupon), runProgress()]);
        setCurrentIndex((x) => x + 1);
        return result;
      };
      await purchaseProgress(props.price, props.coupon);
      for (let i = currentIndex; i < props.cartItems.length; ++i) {
        const priceId = props.cartItems[i];
        currentId = priceId;
        const item = props.upsell.offers.find((o) => o.priceId === priceId)!;
        if (!(await purchaseProgress(item.price))) {
          props.onError();
          return;
        }
      }
      setProgress(100);
      props.onComplete();
    } catch (err) {
      if (currentId) {
        setFailedOfferId(currentId);
        showError(err);
      } else {
        props.onError(err);
      }
    }
  }, [failedOfferId]);

  return (
    <div class="flex flex-col gap-4 pb-8">
      <header class="flex flex-col gap-2 mb-8">
        {failedOfferId && (
          <span class="font-semibold text-red-600 flex justify-between pr-1">
            <span>Checkout failed</span>
          </span>
        )}
        {!failedOfferId && (
          <>
            <span class="font-semibold text-gray-500 flex justify-between pr-1">
              <span>{progress < 100 ? 'Processing...' : 'Finalizing...'}</span>
              {progress >= 100 && <Spinner class="border-green-400" />}
            </span>
            <span
              class={`${
                progress < 100
                  ? 'bg-linear-to-r from-sky-100 to-violet-100 via-indigo-100 animate-pulse'
                  : ''
              } h-2 rounded-full relative overflow-hidden`}
            >
              <span
                class={`${
                  progress < 100 ? 'bg-linear-to-r from-sky-600 to-violet-600' : 'bg-green-400'
                } transition-all absolute inset-y-0 left-0`}
                style={{ width: `${progress}%` }}
              ></span>
            </span>
          </>
        )}
      </header>
      <span class="flex flex-col">
        <span class="inline-flex items-center gap-4 min-w-96 group relative">
          <CourseLogo image={props.course.imagePath} />
          <span class="flex flex-col items-start">
            <span class="font-medium">{props.course.title}</span>
            <span class="text-gray-600">
              <PriceSummary price={props.price} coupon={props.coupon} />
            </span>
          </span>
          <span class="ml-auto text-green-400 pl-2">
            {currentIndex < 1 && <Spinner class="border-indigo-400" />}
            {currentIndex > 0 && <IcoCheckCircle class="size-5" />}
          </span>
        </span>
      </span>
      {props.cartItems.map((offerPriceId, i) => {
        const offer = props.upsell.offers.find((o) => o.priceId === offerPriceId)!;
        const isError = failedOfferId === offerPriceId;
        return (
          <span
            class={`flex items-center justify-between ${
              isError ? 'rounded-xl border-2 border-red-600 overflow-hidden' : ''
            }`}
            key={offer.productId}
          >
            <span class="flex flex-col grow">
              <ProductAndPricePreview
                product={offer.product}
                price={offer.price}
                listPrice={offer.listPrice}
                key={offer.productId}
              ></ProductAndPricePreview>
              {isError && (
                <span class="text-red-600 text-center p-2">
                  <span>Purchase failed</span>{' '}
                  <Button
                    onClick={() => {
                      setCurrentIndex(props.cartItems.indexOf(failedOfferId));
                      setFailedOfferId('');
                    }}
                    class="underline"
                  >
                    Retry
                  </Button>
                </span>
              )}
            </span>
            {!failedOfferId && (
              <span class="ml-auto text-green-400">
                {currentIndex <= i && <Spinner class="border-indigo-400" />}
                {currentIndex > i && <IcoCheckCircle class="size-5" />}
              </span>
            )}
          </span>
        );
      })}
    </div>
  );
}

export function showUpsellCheckoutModal(opts: ModalOptions): Promise<ModalResult | undefined> {
  const upsell = {
    ...opts.upsell,
    offers: opts.upsell.offers.filter((x) => x.productId !== opts.course.productId),
  };
  return showModalForm<ModalResult>(({ resolve }) => {
    const [cartItems, setCartItems] = useState(opts.cartItems || []);
    const [offerIndex, setOfferIndex] = useState(0);
    const [mode, setMode] = useState<ModalMode>(
      !upsell.offers.length ? 'checkout' : opts.cartItems ? 'totals' : 'wizard',
    );
    const close = () => resolve({ ok: false, cartItems });

    const gotoNextOffer = () => {
      const nextIndex = offerIndex + 1;
      if (nextIndex >= upsell.offers.length) {
        setMode('totals');
      } else {
        setOfferIndex(nextIndex);
      }
    };

    useEsc(close, { escapeOnly: true });

    return (
      <UpsellModalWrapper onClose={close} pageId={`${mode}-${offerIndex}`}>
        {opts.isPreview && (
          <section class="bg-yellow-50 border border-yellow-200 p-2 px-4 text-yellow-800 rounded-xl -mb-4">
            This is a preview of what your customers will see.
          </section>
        )}

        <ModalHeader
          title={upsell.title}
          cartSize={cartItems.length + 1}
          gotoTotals={() => setMode('totals')}
        />

        <section class="grow flex flex-col justify-center gap-6 max-w-full mx-auto min-w-96">
          {mode === 'wizard' && (
            <OfferForm
              offer={upsell.offers[offerIndex]}
              onReject={gotoNextOffer}
              onAccept={() => {
                setCartItems((x) => [...x, upsell.offers[offerIndex].priceId]);
                gotoNextOffer();
              }}
            />
          )}
          {mode === 'totals' && (
            <TotalsForm
              {...opts}
              upsell={upsell}
              gotoCheckout={() => setMode('checkout')}
              cartItems={cartItems}
              toggleCartItem={(item) => {
                setCartItems((cartItems) => {
                  if (cartItems.includes(item.priceId)) {
                    return cartItems.filter((x) => x !== item.priceId);
                  } else {
                    return [...cartItems, item.priceId];
                  }
                });
              }}
            />
          )}
          {mode === 'checkout' && (
            <CheckoutForm
              {...opts}
              upsell={upsell}
              cartItems={cartItems}
              onComplete={() => resolve({ ok: true, cartItems })}
              onError={(err) => {
                err && showError(err);
                close();
              }}
            />
          )}
        </section>
      </UpsellModalWrapper>
    );
  });
}

export function showUpsellPreviewModal(upsell: ModalOptions['upsell']) {
  return showUpsellCheckoutModal({
    isPreview: true,
    upsell,
    course: { title: 'Example course', productId: 'prod-example', id: 'example' },
    price: { ...upsell.offers[0].price, id: 'price-example', priceInCents: 23456 },
    coupon: undefined,
    purchase: () => new Promise((r) => setTimeout(r, 1000)),
  });
}
