import './user-detail';
import { RouteProps, router } from '@components/router';
import { FixedContent, FixedPage } from '@components/fixed-page';
import { AdminNav, AdminTabHeader, AdminTabWrapper } from '@components/admin-nav';
import { AppRoute } from 'client/lib/app-route/types';
import { rpx } from 'client/lib/rpx-client';
import { BtnPrimary, BtnSecondary } from '@components/buttons';
import { ModalForm, showModalForm } from '@components/modal-form';
import {
  AutoForm,
  ClearFormState,
  FormGroup,
  useAutoFormState,
  useUpdateUrlSearchParamsEffect,
} from '@components/async-form';
import { Password } from '@components/password';
import { useCurrentTenant, useCurrentUser } from '@components/router/session-context';
import { UserLevelFormField } from './user-level-form-field';
import { UserProfileIcon } from '@components/avatars';
import { useEffect, useMemo, useState } from 'preact/hooks';
import { SearchBox } from '@components/search-box';
import { serialAsync } from 'client/utils/serial-async';
import { AccountTier, UserLevel } from 'server/types';
import { showError } from '@components/app-error';
import { useDidUpdateEffect } from 'client/utils/use-did-update-effect';
import { useDebouncedEffect } from 'client/utils/debounce';
import { compactDate } from 'shared/dateutil';
import { Filter } from '@components/filter';
import { LoadingIndicator } from '@components/loading-indicator';
import * as fmt from 'client/lib/fmt';
import { Toggle } from '@components/toggle';

function find(opts: typeof defaultFilters & { cursor?: string }) {
  return rpx.admin.getTenantUsers({
    searchTerm: opts.q,
    courseTitle: opts.course,
    cursor: opts.cursor,
    userLevels: opts.userLevel === 'all' ? undefined : [opts.userLevel as UserLevel],
    userTiers: opts.userTier === 'all' ? undefined : [opts.userTier as AccountTier],
    crossTenant: opts.crossTenant,
    // A bit explicit to make TypeScript happy
    sortBy: opts.sortBy === 'email' ? 'email' : 'name',
  });
}

async function load(route: AppRoute) {
  const initialFormState = {
    sortBy: route.params.sortBy || defaultFilters.sortBy,
    q: route.params.q || defaultFilters.q,
    userLevel: route.params.userLevel || defaultFilters.userLevel,
    userTier: route.params.userTier || defaultFilters.userTier,
    course: route.params.course || defaultFilters.course,
    crossTenant: false,
  };
  const { users, cursor } = await find(initialFormState);
  return {
    initialFormState,
    users,
    cursor,
  };
}

type Data = Awaited<ReturnType<typeof load>>;

const gotoUser = (user: { id: UUID }) => router.goto(`/admin/people/${user.id}`);

const defaultFilters = {
  sortBy: 'email',
  q: '',
  userLevel: 'all',
  userTier: 'all',
  course: '',
  crossTenant: false,
};

function Page({ state, setState, route }: RouteProps<Data>) {
  const currentUser = useCurrentUser()!;
  const currentTenant = useCurrentTenant();
  const [form, ctx] = useAutoFormState({
    defaultState: defaultFilters,
    initialState: state.initialFormState,
  });

  useUpdateUrlSearchParamsEffect(form);

  useEffect(() => {
    if (route.url === '/admin') {
      router.rewrite('/admin/people');
    }
  }, []);

  const fetchNow = useMemo(() => {
    return serialAsync(async (opts: typeof form, cursor = '') => {
      try {
        ctx.setIsProcessing(true);
        const result = await find({ ...opts, cursor });
        if (cursor) {
          setState((s) => ({ ...s, ...result, users: [...s.users, ...result.users] }));
        } else {
          setState((s) => ({ ...s, ...result }));
        }
      } catch (err) {
        showError(err);
      } finally {
        ctx.setIsProcessing(false);
      }
    });
  }, []);

  useDidUpdateEffect(() => fetchNow(form), [form.userTier, form.userLevel, form.crossTenant]);
  useDidUpdateEffect(() => form.q === '' && fetchNow(form), [form.q]);
  useDidUpdateEffect(() => form.course === '' && fetchNow(form), [form.course]);
  useDebouncedEffect(() => fetchNow(form), [form]);

  const createNewUser = () => {
    showModalForm(() => {
      const tenant = useCurrentTenant();
      const [level, setLevel] = useState<UserLevel>('student');

      return (
        <ModalForm
          title="Create a new user"
          confirmButtonText="Create user"
          onSubmit={async (data) => {
            const result = await rpx.admin.createTenantUser(data);
            gotoUser(result);
          }}
        >
          <div class="space-y-4 my-6">
            <FormGroup prop="name">
              <label class="space-y-1">
                <span>Name</span>
                <input class="ruz-input" placeholder="Name" type="text" name="name" />
              </label>
            </FormGroup>
            <FormGroup prop="email">
              <label class="space-y-1">
                <span>Email</span>
                <input
                  class="ruz-input"
                  placeholder="Email"
                  type="email"
                  name="email"
                  data-private
                />
              </label>
            </FormGroup>
            <FormGroup prop="password">
              <label class="space-y-1">
                <span>Password</span>
                <Password name="password" />
              </label>
            </FormGroup>
            <UserLevelFormField level={level} currentUser={currentUser} onChange={setLevel} />
            {tenant.isCore && level === 'guide' && (
              <FormGroup prop="tier">
                <label class="space-y-1">
                  <span>Tier</span>
                </label>
                <select class="ruz-input" name="tier">
                  {fmt.tiers.map((t) => (
                    <option key={t} value={t}>
                      Tier: {fmt.tierName(t)}
                    </option>
                  ))}
                </select>
              </FormGroup>
            )}
          </div>
        </ModalForm>
      );
    });
  };

  const userLevelOptions = [
    { title: 'All', value: 'all' },
    { title: 'Student', value: 'student' },
    { title: 'Guide', value: 'guide' },
    { title: 'Admin', value: 'admin' },
  ];
  if (currentUser.level === 'superadmin') {
    userLevelOptions.push({ title: 'Superadmin', value: 'superadmin' });
  }

  return (
    <FixedPage>
      <FixedContent class="bg-white">
        {ctx.isProcessing && <LoadingIndicator />}
        <AdminNav currentPage="people" pageTitle="People" />
        <AdminTabWrapper>
          <AdminTabHeader title="People">
            <BtnPrimary class="rounded-full px-8" onClick={createNewUser}>
              Create new user
            </BtnPrimary>
          </AdminTabHeader>
          <AutoForm class="mb-6 space-y-6" ctx={ctx} onSubmit={async () => {}}>
            <div class="flex justify-between">
              <div class="inline-flex">
                <Filter
                  label="Sort by"
                  name="sortBy"
                  value={form.sortBy}
                  options={[
                    { title: 'Email', value: 'email' },
                    { title: 'Name', value: 'name' },
                  ]}
                />
                <Filter
                  label="Role"
                  name="userLevel"
                  value={form.userLevel}
                  options={userLevelOptions}
                />
                {currentUser.level === 'superadmin' && (
                  <Filter
                    label="Tier"
                    name="userTier"
                    value={form.userTier}
                    options={[
                      { title: 'All', value: 'all' },
                      { title: 'Free', value: 'free' },
                      { title: 'Core', value: 'core' },
                      { title: 'Pro', value: 'pro' },
                    ]}
                  />
                )}
              </div>
              <SearchBox
                name="q"
                prefix="User:"
                containerClass="w-80 ml-4"
                placeholder="Search by id, name, or email"
                focusOnce
                value={form.q}
              />
              <SearchBox
                name="course"
                prefix="Course:"
                containerClass="w-80 ml-4"
                placeholder="In course id or title"
                value={form.course}
              />
            </div>

            <ClearFormState>Clear all filters and search terms.</ClearFormState>
          </AutoForm>

          {!state.users.length && <p>No users match those filters.</p>}
          {!!state.users.length && (
            <section>
              <div class="table table-auto bg-white rounded-sm border w-full divide-y">
                <div class="table-row bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  <div class="table-cell pl-4 pr-2 py-2">User</div>
                  <div class="table-cell pl-4 pr-2 py-2">Role</div>
                  <div class="table-cell pl-4 pr-2 py-2">ID</div>
                  <div class="table-cell pl-4 pr-2 py-2">Joined</div>
                </div>
                {state.users.map((u) => (
                  <a
                    key={u.id}
                    class="table-row text-inherit hover:bg-indigo-50 text-sm"
                    href={`/admin/people/${u.id}`}
                  >
                    <span class="table-cell p-4 border-t align-top">
                      <span class="flex items-center">
                        <UserProfileIcon user={u} size="w-10 h-10" />
                        <span class="flex flex-col ml-4">
                          <strong>{u.name}</strong>
                          <span>{u.email}</span>
                        </span>
                      </span>
                    </span>
                    <span class="table-cell p-4 border-t align-top">
                      {u.level}
                      {currentUser.level === 'superadmin' && currentTenant.isCore && (
                        <span class="block opacity-75">Tier: {u.tier || 'none'}</span>
                      )}
                    </span>
                    <span class="table-cell p-4 border-t align-top">
                      {u.id}
                      {u.courseTitle && (
                        <span class="block line-clamp-1 overflow-hidden max-w-96 text-ellipsis opacity-75">
                          Course: {u.courseTitle}
                        </span>
                      )}
                    </span>
                    <span class="table-cell p-4 border-t align-top">
                      <span>{compactDate(u.createdAt)}</span>
                    </span>
                  </a>
                ))}
              </div>

              {state.cursor && (
                <footer class=" text-center p-4">
                  <BtnSecondary
                    isLoading={ctx.isProcessing}
                    onClick={(e: any) => {
                      e.target.blur();
                      fetchNow(form, state.cursor);
                    }}
                  >
                    Load more
                  </BtnSecondary>
                </footer>
              )}
            </section>
          )}
          {currentUser.level === 'superadmin' && (
            <label class="flex gap-3 py-4 cursor-pointer mt-2">
              <Toggle
                checked={form.crossTenant}
                onClick={() => ctx.setState((s) => ({ ...s, crossTenant: !s.crossTenant }))}
              />
              <span>Search across all tenants.</span>
            </label>
          )}
        </AdminTabWrapper>
      </FixedContent>
    </FixedPage>
  );
}

router.add({
  load,
  authLevel: 'admin',
  url: '/admin/people',
  render: Page,
});

router.add({
  load,
  authLevel: 'admin',
  url: '/admin',
  render: Page,
});
