/**
 * The basic calendar component. Most (maybe all) usages of this involve a
 * date and a possible onChange. We allow the component to be controlled and
 * uncontrolled. That is, it can be used as a basic input element whose state
 * is internally managed, and as a component whose state is externally managed.
 *
 * However, we always keep internal state in a non-date object. This allows us
 * to handle invalid dates as the user types. For example, let's say the user
 * is typing in the hour input, and has entered some invalid text, or has
 * cleared it in order to type a new hour.
 *
 * We reset our internal state if the external value changes to a value that
 * we didn't specify via the latest call to `onChange`.
 */

import { JSX } from 'preact';
import { Dispatch, StateUpdater, useEffect, useMemo, useRef, useState } from 'preact/hooks';
import {
  dateNum,
  dateOrUndefined,
  datesEq,
  dateToTime,
  isValidDate,
  mkdate,
  shiftMonth,
  Time,
  today,
} from './date-util';
import { isNullish } from 'shared/utils';

interface Props {
  dayFormatter: Intl.DateTimeFormat;
  monthFormatter: Intl.DateTimeFormat;
  years: number[];
  today: Date;
  active: Date;
  value?: Date;
  min?: Date;
  time: Time;
  includeTime?: boolean;
  onChange(dt: Date | undefined): void;
  colClass: string;
  isDisabled(dt: Date, opts: Props): boolean;
  dateClass(dt: Date, opts: Props): string;
  Footer?(props: { state: Props; setState: Dispatch<StateUpdater<Props>> }): JSX.Element;
}

const weekdays = new Array(7).fill(0).map((_, i) => i);

const months = new Array(12).fill(0).map((_, i) => i);

const focusClass = `focus:outline-indigo-600 focus:outline outline-2 outline-offset-2`;

const timePartClass = `w-12 text-center rounded-sm sm:text-sm border-gray-300`;

const defaults: Props = {
  dayFormatter: new Intl.DateTimeFormat(undefined, { weekday: 'short' }),
  monthFormatter: new Intl.DateTimeFormat(undefined, { month: 'long' }),
  years: new Array(50).fill(0).map((_, i) => new Date().getFullYear() + i - 25),
  today: today(),
  active: today(),
  colClass: 'block uppercase text-xs font-semibold text-gray-400 text-center',
  isDisabled(dt: Date, opts: Props) {
    const min = dateOrUndefined(opts.min) || opts.today;
    return dateNum(dt) < dateNum(min);
  },
  time: dateToTime(today()),
  includeTime: true,
  onChange() {},
  dateClass(dt: Date, opts: Props) {
    const isToday = datesEq(dt, opts.today);
    const isDisabled = opts.isDisabled(dt, opts);
    const isSelected = datesEq(dt, opts.value);
    const isThisMonth = dt.getMonth() === opts.active.getMonth();
    let opacity = '';
    let color = '';
    let bg = '';
    let weight = '';

    // opacity
    if (!isSelected && isDisabled) {
      opacity = 'opacity-40';
    } else if (!isSelected && !isThisMonth) {
      opacity = 'opacity-60';
    }

    // color
    if (isSelected) {
      color = 'text-white';
    } else if (isDisabled) {
      color = 'text-gray-500';
    } else if (isToday) {
      color = 'text-indigo-600';
    } else if (isThisMonth) {
      color = 'text-gray-900';
    } else {
      color = 'text-inherit';
    }

    // bg
    if (isSelected) {
      bg = 'bg-indigo-600 hover:bg-indigo-700';
    } else if (!isDisabled) {
      bg = 'hover:bg-gray-100';
    }

    // weight
    if (isToday) {
      weight = 'font-bold';
    } else if (isThisMonth) {
      weight = 'font-semibold';
    }

    return `${weight} ${opacity} ${bg} ${color} ${focusClass} ${
      isSelected ? 'dp-selected-date' : ''
    } ${
      isToday ? 'dp-today' : ''
    } dp-date font-inherit w-8 aspect-square rounded-full appearance-none border-none`;
  },
};

function calArray(currentDate: Date, dayOffset: number) {
  const result: Date[] = [];
  const iter = new Date(currentDate);

  // Move iter back to the start of the week as defined by dayOffset
  iter.setDate(1);
  iter.setDate(1 - iter.getDay() + dayOffset);

  // If we are showing monday as the 1st of the week,
  // and the monday is the 2nd of the month, the sunday
  // won't show, so we need to shift backwards.
  if (dayOffset && iter.getDate() === dayOffset + 1) {
    iter.setDate(dayOffset - 6);
  }

  // We are going to have 6 weeks always displayed to keep a consistent
  // calendar size
  for (let day = 0; day < 6 * 7; ++day) {
    result.push(new Date(iter));
    iter.setDate(iter.getDate() + 1);
  }

  return result;
}

function Select(props: { value: string; values: string[]; onChange(value: string): void }) {
  return (
    <select
      class={`bg-transparent text-inherit focus:border-none hover:bg-gray-100 font-semibold text-center cursor-pointer rounded-md p-2 border-none bg-none ${focusClass}`}
      value={props.value}
      onChange={(e) => props.onChange((e.target as HTMLSelectElement).value)}
    >
      {props.values.map((value) => (
        <option key={value} selected={value === props.value}>
          {value}
        </option>
      ))}
    </select>
  );
}

function MonthSelector(
  props: Pick<Props, 'active' | 'monthFormatter'> & {
    onChange(month: number): void;
  },
) {
  const values = useMemo(
    () =>
      months.map((month) =>
        props.monthFormatter.format(
          mkdate((dt) => {
            dt.setDate(1);
            dt.setMonth(month);
          }),
        ),
      ),
    [props.monthFormatter],
  );
  return (
    <Select
      value={values[props.active.getMonth()]}
      values={values}
      onChange={(month) => props.onChange(values.indexOf(month))}
    />
  );
}

function retainCalendarFocus(activeDate: Date, child: HTMLElement) {
  const section = child.closest('section');
  requestAnimationFrame(() => {
    section
      ?.querySelector<HTMLButtonElement>(`button[data-date="${activeDate.toDateString()}"]`)
      ?.focus();
  });
}

function useCalAnimation(activeDate: Date) {
  // When the month changes, we'll slide the calendar dates left / right
  // based on whether the previous active date was ahead / behind the new
  // one. It's a bit hacky, but works...
  const [animation, setAnimation] = useState('');
  const prevActive = useRef(activeDate);
  useEffect(() => {
    const month = activeDate.getMonth();
    if (prevActive.current.getMonth() !== month) {
      const newAnimation = prevActive.current > activeDate ? 'an-cal-left' : 'an-cal-right';

      setAnimation(newAnimation);
      prevActive.current = activeDate;
      const onAnimationEnd = (e: AnimationEvent) => {
        if (e.animationName === newAnimation) {
          setAnimation('');
        }
      };
      addEventListener('animationend', onAnimationEnd);
      return () => removeEventListener('animationend', onAnimationEnd);
    }
  }, [activeDate.getMonth()]);
  return animation;
}

export function DatePicker(props: Partial<Props>) {
  const [state, setState] = useState(() => {
    const active = props.active || props.value || defaults.active;
    return { ...defaults, ...props, active, time: dateToTime(active) };
  });
  const { time } = state;
  const dates = useMemo(() => calArray(state.active, 0), [state.active]);
  const navBtnClass = `${focusClass} text-2xl w-8 h-8 aspect-square hover:bg-gray-100 rounded-2xl leading-3 inline-flex items-center justify-center`;
  const animation = useCalAnimation(state.active);

  const setValue = (f: (s: Props) => Props) => {
    let changedValue: Date | undefined;

    setState((s) => {
      const oldValue = s.value;
      const newState = f(s);
      const newTime = newState.time;
      const newValue =
        newState.value &&
        mkdate((dt) => {
          const hh = parseInt(newTime.hh, 10);
          const ampm = newTime.ampm.toLowerCase();
          const hourOffset = ampm === 'pm' ? 12 : 0;
          const hours = (hh === 12 ? 0 : hh) + hourOffset;
          const minutes = parseInt(newTime.mm, 10);
          if (!isNaN(hours) || !isNaN(minutes)) {
            dt.setHours(hours || 0, minutes || 0);
          }
        }, newState.value);
      if (oldValue?.getTime() !== newValue?.getTime()) {
        changedValue = newValue;
      }
      return {
        ...newState,
        value: newValue,
      };
    });

    if (changedValue) {
      props.onChange?.(changedValue);
    }
  };

  // Update our internal time state and call onChange if the result is a valid
  // datetime.
  const setTime = (f: (t: Time) => Time) => {
    setValue((s) => ({ ...s, time: f(s.time) }));
  };

  useEffect(() => {
    // Allow reset / clear
    if (isNullish(props.value)) {
      setState((s) => ({ ...s, value: undefined }));
      return;
    }

    // Ignore invalid dates
    const propsValue = new Date(props.value);
    if (isValidDate(propsValue)) {
      return;
    }

    if (propsValue.getTime() !== state.value?.getTime()) {
      const fmt = new Intl.DateTimeFormat('en-US', {
        hour12: true,
        hourCycle: 'h12',
        timeStyle: 'short',
      });
      const [hh, mm, ampm] = fmt.format(propsValue).split(/[:\s]/);
      setState((s) => ({
        ...s,
        value: propsValue,
        time: { hh, mm, ampm },
      }));
    }
  }, [props.value]);

  return (
    <div class="p-4 w-96 mx-auto flex flex-col gap-4 overflow-hidden border rounded-2xl">
      <header class="flex gap-2 items-center justify-center">
        <button
          type="button"
          class={`mr-auto ${navBtnClass}`}
          aria-label="Previous month"
          onClick={() => {
            setState((s) => ({
              ...s,
              active: shiftMonth(s.active, -1),
            }));
          }}
        >
          ‹
        </button>
        <MonthSelector
          active={state.active}
          monthFormatter={state.monthFormatter}
          onChange={(month) =>
            setState((s) => ({
              ...s,
              active: mkdate((dt) => dt.setMonth(month), s.active),
            }))
          }
        />
        <Select
          value={state.active.getFullYear().toString()}
          values={state.years.map((y) => y.toString())}
          onChange={(year) => {
            setState((s) => ({
              ...s,
              active: mkdate((dt) => dt.setFullYear(Number(year)), s.active),
            }));
          }}
        />
        <button
          type="button"
          class={`ml-auto ${navBtnClass}`}
          aria-label="Next month"
          onClick={() => {
            setState((s) => ({
              ...s,
              active: shiftMonth(s.active, 1),
            }));
          }}
        >
          ›
        </button>
      </header>
      <section
        class={`grid grid-cols-7 gap-2 ${animation}`}
        onKeyDown={(e) => {
          let shift = 0;
          if (e.key === 'ArrowUp') {
            shift = -7;
          } else if (e.key === 'ArrowDown') {
            shift = 7;
          } else if (e.key === 'ArrowLeft') {
            shift = -1;
          } else if (e.key === 'ArrowRight') {
            shift = 1;
          }
          if (shift) {
            const active = mkdate((dt) => dt.setDate(dt.getDate() + shift), state.active);
            setState((s) => ({ ...s, active }));
            retainCalendarFocus(active, e.target as HTMLElement);
          }
        }}
      >
        {weekdays.map((c) => (
          <span key={c} class={state.colClass}>
            {state.dayFormatter.format(
              mkdate((dt) => {
                dt.setDate(dt.getDate() - dt.getDay() + c);
              }),
            )}
          </span>
        ))}
        {dates.map((dt) => (
          <span key={dt.toString()} class="text-center">
            <button
              type="button"
              data-date={dt.toDateString()}
              disabled={state.isDisabled(dt, state)}
              class={state.dateClass(dt, state)}
              tabIndex={datesEq(dt, state.active) ? 0 : -1}
              onClick={(e) => {
                setValue((s) => ({ ...s, value: dt, active: dt }));
                retainCalendarFocus(dt, e.target as HTMLButtonElement);
              }}
            >
              {dt.getDate()}
            </button>
          </span>
        ))}
      </section>
      {state.includeTime && (
        <footer class="flex justify-center items-center gap-2 mt-2">
          <input
            placeholder="h"
            class={timePartClass}
            value={time.hh}
            onFocus={(e: any) => e.target.select()}
            onInput={(e: any) => setTime((s) => ({ ...s, hh: e.target.value }))}
          />
          <span class="-mx-1">:</span>
          <input
            placeholder="m"
            class={timePartClass}
            value={time.mm}
            onFocus={(e: any) => e.target.select()}
            onInput={(e: any) => setTime((s) => ({ ...s, mm: e.target.value }))}
          />
          <button
            type="button"
            class={`${focusClass} px-2.5 p-2 bg-gray-100 border text-inherit hover:bg-indigo-700 hover:text-white rounded-sm`}
            onClick={() => setTime((s) => ({ ...s, ampm: s.ampm === 'PM' ? 'AM' : 'PM' }))}
          >
            {time.ampm}
          </button>
        </footer>
      )}
      {props.Footer && <props.Footer state={state} setState={setState} />}
    </div>
  );
}
