import { RouteProps, router } from '@components/router';
import { FormSection, FormSubSection, Subtext } from '@components/async-form';
import { useState } from 'preact/hooks';
import { FixedContent, FixedPage } from '@components/fixed-page';
import { AdminNav, AdminTabHeader, AdminTabWrapper } from '@components/admin-nav';
import { generateUUID } from 'shared/utils';
import { IcoGripper, IcoTrash } from '@components/icons';
import { Draggable, DraggableProvider, reorderItems } from '@components/draggable';
import { useUnsavedWarning } from '@components/autosaver';
import { BtnPrimary, BtnSecondary, Button } from '@components/buttons';
import { showToast } from '@components/toaster';
import { showError } from '@components/app-error';
import { rpx } from 'client/lib/rpx-client';

async function load() {
  const fieldDefinitions = await rpx.profileFields.getProfileFieldDefinitions();

  return {
    fieldDefinitions,
  };
}

type Data = Awaited<ReturnType<typeof load>>;

function Page({ data }: RouteProps<Data>) {
  const [state, setState] = useState(() => [
    ...data.fieldDefinitions,
    {
      id: generateUUID(),
      label: '',
    },
  ]);
  const [savedState, setSavedState] = useState(state);
  const [isSaving, setIsSaving] = useState(false);
  const removeField = (id: UUID) => setState((s) => s.filter((x) => x.id !== id));
  const setLabel = (id: UUID, label: string) =>
    setState((s) => {
      const item = s.find((x) => x.id === id);
      if (!item) {
        return s;
      }
      const result = [...s];
      const index = result.indexOf(item);
      // Ensure there's always an empty trailing item
      if (label && index === result.length - 1) {
        result.push({ id: generateUUID(), label: '' });
      }
      result[index] = { ...item, label };
      return result;
    });

  const hasUnsavedChanges = savedState !== state;
  const warnOnReload = {
    shouldWarn: hasUnsavedChanges,
  };
  const save = async () => {
    try {
      setIsSaving(true);
      await rpx.profileFields.saveProfileFieldDefinitions({
        fields: state.filter((x) => x.label.length > 0),
      });
      setSavedState(state);
      showToast({
        title: 'Saved changes',
        message: 'Your profile fields have been saved.',
        type: 'ok',
      });
    } catch (err) {
      showError(err);
    } finally {
      setIsSaving(false);
    }
  };

  useUnsavedWarning(() => warnOnReload.shouldWarn);

  return (
    <FixedPage>
      <FixedContent class="bg-white">
        <AdminNav currentPage="fields" pageTitle="Profile Fields" />
        <AdminTabWrapper>
          <AdminTabHeader title="Profile Fields" />
          <FormSection>
            <Subtext>
              Add custom fields for students to fill out when registering for courses. To apply
              these to a course, go to the course settings page.
            </Subtext>
            <FormSubSection class="text-gray-500 space-y-4 max-w-80 mt-8">
              <DraggableProvider
                canHandleDrop={(_, table) => table === 'fields'}
                onDragComplete={() => {}}
                onTargetChange={(opts) => {
                  setState((s) => reorderItems(s, opts));
                }}
              >
                {state.map((f, i) => {
                  const isLast = i === state.length - 1;
                  return (
                    <Draggable
                      key={f.id}
                      class="flex items-center"
                      table={isLast ? 'disabled' : 'fields'}
                      id={f.id}
                    >
                      <span
                        class={`text-gray-400 cursor-move mr-1 -ml-5 ${isLast ? 'invisible' : ''}`}
                        draggable
                      >
                        <IcoGripper class="w-4 h-4 opacity-75" />
                      </span>
                      <input
                        class="ruz-input grow"
                        type="text"
                        value={f.label}
                        placeholder={isLast ? 'Add a new field' : 'Field name'}
                        onInput={(e: any) => setLabel(f.id, e.target.value)}
                      />
                      <Button
                        class={`p-2 rounded-sm ${isLast ? 'invisible' : ''}`}
                        tabIndex={-1}
                        onClick={() => removeField(f.id)}
                      >
                        <IcoTrash class="w-4 h-4 opacity-75" />
                      </Button>
                    </Draggable>
                  );
                })}
              </DraggableProvider>
            </FormSubSection>
          </FormSection>
          {hasUnsavedChanges && (
            <footer class="flex gap-4 an-slide-up mt-6">
              <BtnPrimary disabled={isSaving} onClick={save} isLoading={isSaving}>
                Save changes
              </BtnPrimary>
              <BtnSecondary onClick={() => setState(savedState)}>Cancel</BtnSecondary>
            </footer>
          )}
        </AdminTabWrapper>
      </FixedContent>
    </FixedPage>
  );
}

router.add({
  load,
  authLevel: 'admin',
  url: '/admin/fields',
  render: Page,
});
