/**
 * A page which presents as a modal.
 */

import { ComponentChildren } from 'preact';
import { useEsc } from 'client/utils/use-esc';
import { IcoCheck, IcoExclamation } from '@components/icons';
import { BtnPrimary, BtnWarning, Button, ButtonProps } from '@components/buttons';
import { useBodyScrollLock } from 'client/lib/hooks/use-body-scroll-lock';
import { useState } from 'preact/hooks';
import { showGlobal } from './global-render';
import { AsyncForm } from '@components/async-form';
import { useIntl } from 'shared/intl/use-intl';

export * from './global-render';

export type DialogMode = 'warn' | 'info' | 'ok' | 'none' | 'custom';

type BaseProps = {
  children: ComponentChildren;
  onClose(): void;
  blurBg?: boolean;
  contentWidth?: boolean;
};

export type DialogProps = BaseProps & {
  mode: DialogMode;
  icon?: ComponentChildren;
  title: ComponentChildren;
  children?: ComponentChildren;
  confirmButtonText?: ComponentChildren;
  cancelButtonText?: ComponentChildren;
  errorTitle?: string;
  hideCancel?: boolean;
  onSubmit?(): Promise<unknown>;
};

export function BtnCancel(props: ButtonProps & { isStandalone?: boolean }) {
  return (
    <Button
      type="button"
      class={`rounded-full p-2 px-4 transition-all ${
        props.isStandalone ? 'bg-gray-100 hover:bg-gray-200' : 'hover:bg-gray-100'
      } `}
      {...props}
    >
      {props.children || 'Cancel'}
    </Button>
  );
}

export function BaseDialog(props: BaseProps) {
  useEsc(props.onClose, { escapeOnly: true });

  return (
    <div
      class={`js-dialog fixed inset-0 ${
        props.blurBg === false ? '' : 'backdrop-blur-sm'
      } bg-gray-600/50 flex items-start pt-10 justify-center z-50`}
      onMouseDown={(e) => {
        if (e.target === e.currentTarget) {
          e.preventDefault();
          e.stopPropagation();
          props.onClose();
        }
      }}
    >
      <section
        class={`rounded-t-xl sm:rounded-3xl flex flex-col bg-white shadow-2xl w-full absolute sm:relative bottom-0 ${
          props.contentWidth ? `sm:w-content` : `sm:w-2xl`
        } h-(screen-16) sm:h-auto max-h-(screen-16) sm:max-w-(screen-16) overflow-hidden an-scale-in`}
      >
        {props.children}
      </section>
    </div>
  );
}

export function Dialog(props: DialogProps) {
  const modeColors: Record<DialogMode, string> = {
    none: '',
    custom: '',
    ok: 'bg-green-100 text-green-600',
    warn: 'bg-red-100 text-red-600',
    info: 'bg-indigo-100 text-indigo-600',
  };
  const [isProcessing, setIsProcessing] = useState(false);
  const cancelButtonText = props.cancelButtonText || 'Cancel';

  useBodyScrollLock(true);

  return (
    <BaseDialog blurBg={false} onClose={props.onClose}>
      <AsyncForm
        autoFocus
        onSubmit={async () => {
          if (!props.onSubmit) {
            return props.onClose();
          }
          try {
            setIsProcessing(true);
            await props.onSubmit();
          } catch (err) {
            showDialog({
              mode: 'warn',
              title: props.errorTitle || 'An unknown error occurred',
              children: err.message || err.toString(),
            });
          } finally {
            setIsProcessing(false);
          }
        }}
        class="p-8 flex gap-6"
      >
        {props.mode !== 'none' && (
          <span
            class={`shrink-0 inline-flex size-10 items-center justify-center rounded-full ${
              modeColors[props.mode]
            }`}
          >
            {props.mode === 'custom' && props.icon}
            {props.mode === 'warn' && <IcoExclamation class="size-6" />}
            {props.mode === 'info' && <i class="font-serif font-bold text-xl">i</i>}
            {props.mode === 'ok' && <IcoCheck class="size-6" />}
          </span>
        )}
        <section class="grow">
          {props.title && <h2 class="font-semibold text-base">{props.title}</h2>}
          {props.children && <p class="text-gray-600 text-sm">{props.children}</p>}
          <footer class="flex flex-col sm:flex-row gap-4 mt-6">
            <BtnPrimary
              isLoading={isProcessing}
              class={`rounded-full px-6 ${
                props.mode === 'warn'
                  ? 'bg-red-600 hover:bg-red-500 focus:outline-red-600'
                  : props.mode === 'ok'
                  ? 'bg-green-500 hover:bg-green-600 focus:outline-green-500'
                  : ''
              }`}
            >
              {props.confirmButtonText || (props.onSubmit ? 'OK' : 'Dismiss')}
            </BtnPrimary>
            {!props.hideCancel && <BtnCancel onClick={props.onClose}>{cancelButtonText}</BtnCancel>}
          </footer>
        </section>
      </AsyncForm>
    </BaseDialog>
  );
}

export async function showDialog(props: Omit<DialogProps, 'onClose'>) {
  return showGlobal<boolean>(({ resolve }) => {
    return (
      <Dialog
        {...props}
        onSubmit={async () => {
          const result = props.onSubmit ? await props.onSubmit?.() : true;
          resolve(!!result);
        }}
        onClose={() => {
          resolve(false);
        }}
      />
    );
  });
}

export function StandardDialog(props: {
  onClose(): void;
  title?: ComponentChildren;
  subtitle?: ComponentChildren;
  children?: ComponentChildren;
  contentWidth?: boolean;
}) {
  useBodyScrollLock(true);

  return (
    <BaseDialog blurBg={false} onClose={props.onClose} contentWidth={props.contentWidth}>
      <section class="p-8 flex flex-col gap-4 overflow-y-auto">
        <DialogHeader title={props.title} subtitle={props.subtitle} />
        {props.children}
      </section>
    </BaseDialog>
  );
}

export function DialogHeader(props: { title: ComponentChildren; subtitle?: ComponentChildren }) {
  if (!props.title) {
    return null;
  }
  return (
    <header class="sticky -top-8 -mt-8 bg-white z-10 pt-8 pb-2">
      <h2 class="font-semibold text-base leading-4">{props.title}</h2>
      {props.subtitle && <p class="text-gray-600 text-sm mt-1.5">{props.subtitle}</p>}
      <div class="bg-linear-to-b from-white to-transparent h-3 -inset-x-4 -bottom-3 absolute"></div>
    </header>
  );
}

export function DialogFooter(props: {
  confirmButtonText?: ComponentChildren;
  cancelButtonText?: ComponentChildren;
  mode?: 'warn';
  hideCancel?: boolean;
  hideConfirm?: boolean;
  onClose?(): void;
  isLoading?: boolean;
}) {
  const intl = useIntl();
  const BtnApply = props.mode === 'warn' ? BtnWarning : BtnPrimary;
  return (
    <footer class="flex gap-4 sticky z-10 -bottom-8 bg-white py-2 -mb-8 pb-8">
      <div class="bg-linear-to-b from-transparent to-white h-3 -inset-x-4 -top-3 absolute"></div>
      {!props.hideConfirm && (
        <BtnApply class="rounded-full px-8" isLoading={props.isLoading}>
          {props.confirmButtonText || intl('Apply')}
        </BtnApply>
      )}
      {!props.hideCancel && (
        <BtnCancel onClick={props.onClose} isStandalone={props.hideConfirm}>
          {props.cancelButtonText || intl('Cancel')}
        </BtnCancel>
      )}
    </footer>
  );
}
