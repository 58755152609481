import { showError } from '@components/app-error';
import { FormGroup } from '@components/async-form';
import { Case } from '@components/conditional';
import { DateInput } from '@components/date-picker';
import { IcoCalendar } from '@components/icons';
import { ModalForm, showModalForm } from '@components/modal-form';
import { useCurrentTenant } from '@components/router/session-context';
import { Spinner } from '@components/spinner';
import { Toggle } from '@components/toggle';
import { rpx, RpxResponse } from 'client/lib/rpx-client';
import { useAsyncEffect } from 'client/utils/use-async-effect';
import dayjs from 'dayjs';
import { useState } from 'preact/hooks';
import type { Course } from 'server/types';
import { toHumanTime, toLocalDate } from 'shared/dateutil';
import { URLS } from 'shared/urls';

interface Props {
  course: Pick<Course, 'id' | 'title' | 'accessFormat' | 'isBundle' | 'isProduct'>;
  onCancel: () => void;
}

const ACCESS_FORMAT_SCHEDULE_MESSAGES = {
  openaccess: {
    plural: 'messages and meetings',
    singular: 'message and meeting',
  },
  scheduled: {
    plural: 'modules, messages and meetings',
    singular: 'module, message and meeting',
  },
  ondemand: {
    plural: 'meetings',
    singular: 'meeting',
  },
};

export function showCopyCourseModal(course: Props['course']) {
  showModalForm(({ resolve }) => {
    return <CopyCourseModal course={course} onCancel={() => resolve(undefined)} />;
  });
}

export function CopyCourseModal({ course, onCancel }: Props) {
  const { id, title } = course;
  const { terminology } = useCurrentTenant();
  const courseLabel = course.isProduct
    ? 'product'
    : course.isBundle
    ? 'bundle'
    : terminology.course;

  const [shouldDraftContent, setShouldDraftContent] = useState(true);
  const [shouldCopyPricing, setShouldCopyPricing] = useState(false);
  const [isLoadingFirstDates, setIsLoadingFirstDates] = useState(true);

  const [firstDate, setFirstDate] = useState<
    RpxResponse<typeof rpx.courses.getCourseFirstEventDate> | undefined
  >(undefined);
  const [newDate, setNewDate] = useState<Date | undefined>();

  useAsyncEffect(async () => {
    try {
      const result = await rpx.courses.getCourseFirstEventDate({
        courseId: id,
      });
      if (result?.date) {
        const localDate = toLocalDate(result.date);
        setFirstDate({
          type: result.type,
          date: localDate?.toISOString(),
        });
        // If the first date is in the past, set the new date to now.
        const now = dayjs();
        setNewDate(now.isAfter(localDate) ? now.toDate() : localDate);
      }
    } catch (err) {
      showError(err);
    } finally {
      setIsLoadingFirstDates(false);
    }
  }, [id]);

  async function copyCourse(values: { title: string }) {
    try {
      const { newCourseId } = await rpx.courses.copyCourseAsGuide({
        id,
        title: values.title,
        shouldCopyPricing,
        startDateDiff:
          firstDate?.date && newDate ? dayjs(newDate).diff(firstDate.date, 'minute') : undefined,
        shouldDraftContent,
      });
      // For now, we're going to do a hard redirect to ensure we don't bork
      // any screen that doesn't properly handle changing course id.
      location.assign(URLS.guide.baseUrl({ id: newCourseId, isProduct: course.isProduct }));
    } catch (err) {
      showError(err);
    }
  }

  return (
    <ModalForm
      onClose={onCancel}
      title={<> Copy {courseLabel}</>}
      subtitle={
        <>
          You're copying: <i>{title}</i>
        </>
      }
      onSubmit={copyCourse}
      confirmButtonText={<>Copy {courseLabel}</>}
    >
      <section class="flex flex-col gap-6">
        <FormGroup prop="title">
          <label>
            <span>What should we call the new copy of the {courseLabel}?</span>
            <input
              type="text"
              placeholder={`${courseLabel} title`}
              name="title"
              class="ruz-input"
              defaultValue={`Copy of ${title}`}
            />
          </label>
        </FormGroup>

        <FormGroup prop="shouldCopyPricing">
          <label class="flex gap-4 cursor-pointer">
            <Toggle
              checked={shouldCopyPricing}
              onClick={() => setShouldCopyPricing(!shouldCopyPricing)}
            />
            <span class="flex flex-col">
              <span class="font-semibold">
                Copy sales page, price points and coupons into the new {courseLabel}
              </span>
              <span class="text-gray-500">
                Note: Only the 50 most recently updated price points and coupons will be copied into
                the new {courseLabel}.
              </span>
            </span>
          </label>
        </FormGroup>

        <Case when={!isLoadingFirstDates} fallback={<Spinner />}>
          {firstDate?.date && (
            <>
              <label class="flex gap-4 cursor-pointer">
                <Toggle
                  checked={shouldDraftContent}
                  onClick={() => setShouldDraftContent(!shouldDraftContent)}
                />
                <span class="flex flex-col">
                  <span class="font-semibold">
                    Set all {ACCESS_FORMAT_SCHEDULE_MESSAGES[course.accessFormat].plural} in the new
                    course to "draft"
                  </span>
                  <span class="text-gray-500">
                    If enabled, you'll need to manually schedule these in the new {courseLabel}.
                    Disable this if you want us to schedule everything automatically based on the
                    first {firstDate.type} date you will set below.
                  </span>
                </span>
              </label>

              {!shouldDraftContent && (
                <section class="flex gap-4">
                  <Toggle class="invisible" />
                  <div class="flex flex-col gap-4">
                    <div>
                      The first {firstDate.type} of {title} was scheduled to release on:{' '}
                      <strong>{toHumanTime(firstDate.date)}</strong> (student's timezone).
                    </div>
                    <div>
                      To schedule your new copy of this {courseLabel}, please set a date for this
                      first {firstDate.type}.
                    </div>
                    <div>
                      We'll adjust the remaining{' '}
                      {ACCESS_FORMAT_SCHEDULE_MESSAGES[course.accessFormat].singular} dates to match
                      the pacing in the original {courseLabel}.
                    </div>

                    <label class="flex cursor-pointer w-full">
                      <span class="flex border bg-gray-50 border-gray-300 aspect-square items-center justify-center rounded-l border-r-0">
                        <IcoCalendar />
                      </span>
                      <DateInput
                        wrapperClass="grow flex"
                        class="grow rounded-l-none"
                        name="newDate"
                        value={newDate}
                        onChange={setNewDate}
                        includeTime
                      />
                    </label>
                  </div>
                </section>
              )}
            </>
          )}
        </Case>
      </section>
    </ModalForm>
  );
}
