/**
 * The ruzcal management page wrapper.
 */

import { ComponentChildren } from 'preact';
import { useCurrentUser } from 'client/lib/auth';
import { IcoCalendar, IcoClock, IcoCube, IcoList } from '@components/icons';
import { useDocumentTitle } from 'client/utils/use-document-title';
import { useState } from 'preact/hooks';
import { useBodyScrollLock } from 'client/lib/hooks/use-body-scroll-lock';
import { useEsc } from 'client/utils/use-esc';
import { UserProfileIcon } from '@components/avatars';
import { Dropdown, MenuItem } from '@components/dropdown';

type PageType = 'bookings' | 'availability' | 'meetingtypes' | 'other';

function PageNavlink(props: { href: string; isSelected?: boolean; children: ComponentChildren }) {
  return (
    <a
      href={props.href}
      class={`${
        props.isSelected ? 'bg-gray-200 text-black' : 'text-inherit'
      } relative p-2 px-4 font-semibold rounded-md hover:bg-gray-200 transition-all flex items-center gap-2`}
    >
      {props.children}
    </a>
  );
}

function PageLogo() {
  return (
    <a href="/ruzcal-mgmt" class="text-2xl font-medium flex items-center gap-4">
      <span class="shrink-0 size-10 flex items-center justify-center bg-indigo-600 text-white rounded-full">
        <IcoCalendar class="size-6" />
      </span>
      ruzcal
    </a>
  );
}

function UserMenu() {
  const user = useCurrentUser()!;
  return (
    <Dropdown
      triggerClass="max-w-full"
      hideDownIcon
      noPadding
      class="max-w-full"
      renderMenu={() => (
        <div class="p-2 gap-2 flex flex-col">
          <MenuItem href="/ruzcal-mgmt/settings">Settings</MenuItem>
          <MenuItem href="/logout">Logout</MenuItem>
        </div>
      )}
    >
      <span class="flex items-center gap-4 grow max-w-full">
        <UserProfileIcon user={user} size="size-10 shrink-0" />
        <span class="overflow-hidden whitespace-nowrap text-ellipsis">{user.name}</span>
      </span>
    </Dropdown>
  );
}

function PageNav({ currentPage, title }: { currentPage: PageType; title: string }) {
  const [show, setShow] = useState(false);

  useBodyScrollLock(show);
  useEsc(() => setShow(false), { respectDefault: true, escapeOnly: true });

  return (
    <>
      <header class="flex flex-col lg:hidden sticky top-0 backdrop-blur-lg z-10">
        <div class="px-5 p-4">
          <PageLogo />
        </div>
        <div class="border-y px-3 p-1 flex items-center gap-2">
          <button
            type="button"
            class="aspect-square inline-flex items-center justify-center size-10"
            onClick={() => setShow(!show)}
          >
            <IcoList class="stroke-2 size-5" />
          </button>
          <span class="text-gray-500 font-medium">{title}</span>
        </div>
      </header>
      <div
        class={`fixed lg:hidden transition-all z-20 ${
          show ? 'inset-0 bg-gray-900/40' : '-top-1 -left-1'
        }`}
        onClick={() => setShow(false)}
      ></div>
      <nav
        class={`w-80 shrink-0 flex flex-col border-r fixed inset-y-0 lg:relative bg-gray-100 z-20 transition-all shadow-xl lg:shadow-none ${
          show ? 'left-0' : '-left-80 lg:left-0'
        }`}
        onClick={(e) => {
          if ((e.target as HTMLElement).closest('a')) {
            setShow(false);
          }
        }}
      >
        <header class="flex items-center p-5 py-4">
          <PageLogo />
        </header>
        <div class="p-4 flex flex-col grow gap-3">
          <PageNavlink href="/ruzcal-mgmt/bookings" isSelected={currentPage === 'bookings'}>
            <IcoCalendar class="size-4 shrink-0 opacity-75" />
            Bookings
            {currentPage !== 'bookings' && (
              <>
                <span class="absolute size-3 rounded-full top-2 left-2 bg-green-400"></span>
                <span class="absolute size-3 rounded-full top-2 left-2 bg-green-400 animate-ping"></span>
              </>
            )}
          </PageNavlink>
          <PageNavlink href="/ruzcal-mgmt/availability" isSelected={currentPage === 'availability'}>
            <IcoClock class="size-4 shrink-0 opacity-75" />
            Availability
          </PageNavlink>
          <PageNavlink
            href="/ruzcal-mgmt/meeting-types"
            isSelected={currentPage === 'meetingtypes'}
          >
            <IcoCube class="size-4 shrink-0 opacity-75" />
            Meeting types
          </PageNavlink>
        </div>
        <footer class="mt-auto sticky bottom-0 inset-x-0 bg-gray-100 p-5 py-3">
          <UserMenu />
        </footer>
      </nav>
    </>
  );
}

export function RuzcalMgmtPage({
  title,
  children,
  currentPage,
}: {
  title: string;
  children: ComponentChildren;
  currentPage: PageType;
}) {
  useDocumentTitle([title]);
  return (
    <section class="flex flex-col lg:flex-row h-screen overflow-hidden font-medium bg-gray-50">
      <PageNav currentPage={currentPage} title={title} />
      <div class="grow overflow-auto">{children}</div>
    </section>
  );
}
