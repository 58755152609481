import { CheckoutForm } from './checkout-form';
import { useIntl } from 'shared/intl/use-intl';
import { Fragment } from 'preact';
import { useState } from 'preact/hooks';
import * as pmtmath from 'shared/payments/math';
import { CouponRow, PriceRow } from 'server/types/pmts-schema';
import { StripeContext, StripeInput } from './stripe';
import { useCurrentUser } from 'client/lib/auth';
import { FormGroup } from '../async-form';
import { PurchaseFn } from 'shared/payments/types';
import { BtnPrimary, Button } from '@components/buttons';
import { DefaultSpinner } from '@components/spinner';
import { IcoTrash } from '@components/icons';
import { useAsyncEffect } from 'client/utils/use-async-effect';
import { showError } from '@components/app-error';
import { UpsellOffer, offers } from 'shared/upsells';
import * as fmt from 'shared/payments/fmt';

type PublicProps = {
  collectAddress: boolean;
  price: PriceRow;
  coupon?: CouponRow;
  isGift?: boolean;
  beginPurchase: PurchaseFn;
  onPurchaseComplete(): Promise<unknown>;
};

type Props = PublicProps & {
  offers: SelectableUpsellOffer[];
  ctx: StripeContext | undefined;
  setCtx(ctx: StripeContext): void;
};

type SelectableUpsellOffer = UpsellOffer & {
  isSelected: boolean;
};

type UpsellState = {
  offers: SelectableUpsellOffer[];
  /**
   * The page we're in in the upsell wizard flow. If it starts with
   * "upsell-", it's the name of the offer we're displaying.
   */
  page: 'init' | 'offers' | 'confirm' | 'processing';

  /**
   * Which offer we're on if page is 'upsell'.
   */
  offerIndex: number;
};

// Temporary, hard-coded upsell prices for our next promotion.
const upsellPrices = [
  // Prices for Nov 2024
  // https://github.com/morebetterlabs/ruzuku-v2/issues/5415
  'price-JHVvFuYu7csmiwDhfLMUdA',
  'price-kzuzQIwcPrLgNZgSlhEfGQ',
  'price-SayabmRIhBDp73OchG1o4Q',
  'price-WkUYqBShKw2zXSxwinYUNA',

  // Local price:
  // http://localhost:3000/checkout/price-vfFEhE6KUfu51GYCeuwskQ
  // http://localhost:3000/checkout/price-l7l-2QR-X2bbObtHyyeKYQ
  'price-vfFEhE6KUfu51GYCeuwskQ', // Single payment test
  'price-l7l-2QR-X2bbObtHyyeKYQ', // Payment plan test

  // Staging price:
  // https://ruzukuv2.onrender.com/checkout/price-xQGntkbsm2FXjLzDrwsalg
  // Uncommenting breaks e2e tests, since this price is used there...
  // 'price-xQGntkbsm2FXjLzDrwsalg',

  // Production:
  'price-QZjQcacytg56CYxd2GgppQ',
  'price-7TS3L6-YObi6C-etcdYk4w',
  'price-v29S8vmclv9XSObovPx55g',
  'price-w_EUqPxa1UcDXe-fNQOPyQ',
  'price-kVwlq7tGA5XjMT1u8kNo2Q',
  'price-5a4GSs-dE7p0ur84Xr2DoA',
  'price-xKxtCo7MAdWo_vsfdsqEpQ',
  'price-izh6coPAsvhN2Itode8FEQ',
  'price-ysSG4W8YiE8mdKxNJrtOsA',

  'price-TZ3K_160DL7VCSdH42hdCg',
  'price-9jmDc7V4C4dFkEOFZ0HeBw',
];

export function isUpsellable(price: PriceRow) {
  return upsellPrices.includes(price.id);
}

async function isInvalid(ctx: StripeContext) {
  const elementsResult = await ctx.elements.submit();
  return !!elementsResult.error;
}

function StripeCheckoutForm(props: Props & { onNext(): void }) {
  const { ctx, setCtx, onNext, collectAddress, price, coupon, isGift } = props;
  const intl = useIntl();
  const user = useCurrentUser()!;
  const freeTrialPeriod = pmtmath.freeTrialPeriod({ price, coupon });
  const paymentRequired = !freeTrialPeriod && !!pmtmath.initialPrice({ price, coupon });

  return (
    <CheckoutForm
      actionText={
        freeTrialPeriod
          ? intl('Start Free Trial ⤑')
          : isGift
          ? intl('Purchase Gift ⤑')
          : intl('Sign Up ⤑')
      }
      onSubmit={async () => {
        if (!ctx) {
          return;
        }
        if (await isInvalid(ctx)) {
          return;
        }
        onNext();
      }}
    >
      <h2 class="text-lg font-medium">Checkout</h2>
      <FormGroup prop="paymentMethod">
        <StripeInput
          customerName={user?.name}
          priceId={price.id}
          onReady={setCtx}
          collectAddress={collectAddress || false}
        />
      </FormGroup>
      {!paymentRequired && (
        <p class="text-gray-600 text-center mt-4">
          {!!freeTrialPeriod &&
            intl(`You will not be charged until the end of the free trial period.`)}
          {!freeTrialPeriod && intl(`You will not be charged today.`)}
        </p>
      )}
    </CheckoutForm>
  );
}

function UpsellForm(
  props: Props & { offer: SelectableUpsellOffer; onNext(opts: { isSelected: boolean }): void },
) {
  return (
    <div
      class="fixed z-10 inset-0 bg-gray-800/50 flex items-center justify-center p-8"
      key={props.offer.name}
    >
      <div class="bg-white rounded-md max-h-(screen-16) overflow-auto max-w-3xl">
        <div class="flex flex-col gap-8 items-center an-fade-in-left p-10 py-14">
          <div
            class="flex flex-col gap-4 max-w-2xl"
            dangerouslySetInnerHTML={{ __html: props.offer.markup }}
          ></div>
        </div>
        <footer class="flex w-full flex-col gap-4 sticky bottom-0 bg-white px-10 py-6">
          <BtnPrimary onClick={() => props.onNext({ isSelected: true })}>
            {props.offer.acceptText}
          </BtnPrimary>
          <Button class="text-indigo-600 px-10" onClick={() => props.onNext({ isSelected: false })}>
            {props.offer.rejectText}
          </Button>
        </footer>
      </div>
    </div>
  );
}

function ProcessingForm({
  ctx,
  offers,
  beginPurchase,
  onPurchaseComplete,
  onError,
}: Props & { onError(err: Error): void }) {
  const user = useCurrentUser()!;
  useAsyncEffect(async () => {
    try {
      if (!ctx) {
        return;
      }
      const { error, paymentMethod } = await ctx.stripe.createPaymentMethod({
        type: 'card',
        card: ctx.elements.getElement('card')!,
        billing_details: {
          name: user.name,
          email: user.email,
        },
        metadata: {
          userId: user.id,
        },
      });

      if (error) {
        console.error(error);
        throw error;
      }
      if (!paymentMethod) {
        throw new Error(`Failed to create Stripe payment method.`);
      }

      const purchase = () =>
        beginPurchase({
          offers: offers.filter((o) => o.isSelected).map((o) => o.name),
          stripePaymentMethod: paymentMethod.id,
        });

      const result = await purchase();
      if (!result) {
        return;
      }
      if (result.type === 'action') {
        // Stripe requires further action, such as 3D secure
        const actionResult = await ctx.stripe.confirmCardPayment(result.action, {
          setup_future_usage: 'off_session',
        });
        if (!actionResult.paymentIntent) {
          console.error(actionResult);
          return;
        }

        // Confirm the action with Stripe
        await purchase();
      }
      await onPurchaseComplete();
      // Give the screen a chance to transition away so we don't get a flicker that
      // temporarily shows the credit card form again.
      await new Promise((r) => setTimeout(r, 2000));
    } catch (err) {
      showError(err);
      onError(err);
    }
  }, []);

  return (
    <div class="fixed z-10 inset-0 bg-gray-800/50 flex items-center justify-center p-8">
      <div class="bg-white p-10 py-14 rounded-md">
        <div class="flex flex-col gap-8 max-w-3xl items-center an-fade-in-left">
          <h2 class="text-3xl font-semibold text-center">Processing payment... </h2>
          <DefaultSpinner />
        </div>
      </div>
    </div>
  );
}

function ConfirmForm({
  offers,
  price,
  coupon,
  onNext,
  toggleOffer,
}: Props & { toggleOffer(name: string): void; onNext(): void }) {
  const ruzukuPrice = pmtmath.initialPrice({
    price,
    coupon,
  });
  return (
    <div class="fixed z-10 inset-0 bg-gray-800/50 flex items-center justify-center p-8">
      <div class="bg-white p-10 py-14 rounded-md">
        <div class="flex flex-col gap-8 max-w-3xl items-center an-fade-in-left">
          <h2 class="text-3xl font-semibold text-center">Checkout</h2>
          <p>
            If everything looks good, click "checkout" below, and start building amazing courses!
          </p>
          <section class="flex flex-col gap-8">
            <div class="inline-grid grid-cols-2 gap-2 gap-x-6">
              <span>Ruzuku</span>
              <span>{fmt.price({ priceInCents: ruzukuPrice, currency: 'USD' })}</span>
              {offers.map((o) => (
                <Fragment key={o.name}>
                  <span>{o.displayName}</span>
                  {!o.isSelected && (
                    <Button class="text-indigo-600 text-left" onClick={() => toggleOffer(o.name)}>
                      + Add to cart
                    </Button>
                  )}
                  {o.isSelected && (
                    <span class="inline-flex gap-4 justify-between">
                      {fmt.price({ priceInCents: o.priceInCents, currency: 'USD' })}
                      <Button onClick={() => toggleOffer(o.name)}>
                        <IcoTrash />
                      </Button>
                    </span>
                  )}
                </Fragment>
              ))}
              <strong class="font-bold">Due Today</strong>
              <strong class="font-bold">
                {fmt.price({
                  priceInCents:
                    ruzukuPrice +
                    offers.reduce((acc, o) => acc + (o.isSelected ? o.priceInCents : 0), 0),
                  currency: 'USD',
                })}
              </strong>
            </div>
          </section>

          <footer class="flex flex-col gap-4 w-full">
            <BtnPrimary onClick={() => onNext()}>Checkout ⤑</BtnPrimary>
          </footer>
        </div>
      </div>
    </div>
  );
}

export function UpsellStripeCheckoutForm(props: PublicProps) {
  const { isGift, price } = props;
  const [ctx, setCtx] = useState<StripeContext | undefined>(undefined);
  const [state, setState] = useState<UpsellState>(() => ({
    page: 'init',
    offerIndex: 0,
    offers: offers.map((o) => ({ ...o, isSelected: false })),
  }));

  const shouldUpsell = !isGift && upsellPrices.includes(price.id);
  const childProps: Omit<Props, 'onNext'> = { ...props, offers: state.offers, ctx, setCtx };
  return (
    <>
      <StripeCheckoutForm
        {...childProps}
        onNext={() => setState((s) => ({ ...s, page: shouldUpsell ? 'offers' : 'processing' }))}
      />
      {state.page === 'offers' && (
        <UpsellForm
          {...childProps}
          offer={state.offers[state.offerIndex]}
          onNext={({ isSelected }) => {
            setState((s) => {
              const offerIndex = s.offerIndex + 1;
              const offers = [...s.offers];
              offers[s.offerIndex] = { ...offers[s.offerIndex], isSelected };
              return {
                ...s,
                offerIndex,
                page: offerIndex >= s.offers.length ? 'confirm' : 'offers',
                offers,
              };
            });
          }}
        />
      )}
      {state.page === 'confirm' && (
        <ConfirmForm
          {...childProps}
          toggleOffer={(offerName) =>
            setState((s) => ({
              ...s,
              offers: s.offers.map((o) =>
                o.name === offerName ? { ...o, isSelected: !o.isSelected } : o,
              ),
            }))
          }
          onNext={() => setState((s) => ({ ...s, page: 'processing' }))}
        />
      )}
      {state.page === 'processing' && (
        <ProcessingForm
          {...childProps}
          onError={() => setState((s) => ({ ...s, offerIndex: 0, page: 'init' }))}
        />
      )}
    </>
  );
}
