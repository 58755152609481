import { RouteProps, router } from '@components/router';
import { AppRoute } from 'client/lib/app-route/types';
import { RpxResponse, modulesService, rpx } from 'client/lib/rpx-client';
import { groupBy } from 'shared/utils';
import { GuideProductPage } from '@components/guide-course-page';
import { useDidUpdateEffect } from 'client/utils/use-did-update-effect';
import { useMemo, useCallback, useRef, useState } from 'preact/hooks';
import { serialAsync } from 'client/utils/serial-async';
import { ContentEditor } from './content-editor';
import { LoadingIndicator } from '@components/loading-indicator';
import { Draggable, DraggableProvider } from '@components/draggable';
import { showError } from '@components/app-error';
import { BtnPrimary, Button } from '@components/buttons';
import { Dropdown, MenuItem } from '@components/dropdown';
import { IcoBook, IcoDotsHorizontal, IcoList } from '@components/icons';
import { showModalForm } from '@components/modal-form';
import { showToast } from '@components/toaster';
import { CopyModal } from '../guide-course-modules/copy-modal';
import { SlideOver } from '@components/slide-over';
import { EmptyPage } from '@components/empty-page';
import { PageTitle } from '@components/headings';
import { showDialog } from '@components/dialog';

type Props = RouteProps<Awaited<ReturnType<typeof load>>>;
type Lesson = NonNullable<Props['state']['lesson']>;
type LessonSetter = (f: (lesson: Lesson) => Lesson) => void;

async function load(route: AppRoute) {
  const { courseId } = route.params;
  const [product, lessonState] = await Promise.all([
    rpx.courses.getGuideCourse({ id: courseId }),
    rpx.lessons.getFullLessonState({ courseId }),
  ]);

  const lessonMap = groupBy((l) => l.moduleId, lessonState.lessons);
  const lessons = lessonState.modules.flatMap<
    Pick<(typeof lessonState.lessons)[0], 'id' | 'title' | 'seq'>
  >((m) => lessonMap[m.id]);
  const currentLessonId = route.params.lessonId || lessons[0]?.id;

  return {
    product,
    module: lessonState.modules[0]?.id ? lessonState.modules[0] : undefined,
    lessons,
    lesson: currentLessonId
      ? await rpx.lessons.getLesson({ courseId, lessonId: currentLessonId })
      : undefined,
  };
}

function useLessonFetcher(
  courseId: string,
  lessonId: string,
  onLoad: (lesson: RpxResponse<typeof rpx.lessons.getLesson>) => void,
) {
  const loading = useRef(lessonId);
  loading.current = lessonId;
  const fetchLesson = useMemo(
    () =>
      serialAsync(async (newLessonId: string) => {
        const lesson = await rpx.lessons.getLesson({ courseId, lessonId: newLessonId });
        if (loading.current === newLessonId) {
          onLoad(lesson);
        }
      }),
    [],
  );
  useDidUpdateEffect(() => fetchLesson(lessonId), [lessonId]);
  return loading.current;
}

function PagesNav(props: Props & { createNewPage: () => void }) {
  const {
    state: { product, lessons, lesson, module },
    setState,
  } = props;

  const saveLessonOrder = async () => {
    try {
      if (module) {
        await rpx.modules.reorderLessons({
          moduleId: module.id,
          lessonIds: lessons.map((l) => l.id),
        });
      }
    } catch (err) {
      showError(err);
    }
  };

  return (
    <nav class="flex flex-col gap-4 p-2 py-4 w-80">
      <DraggableProvider
        onDragComplete={saveLessonOrder}
        onTargetChange={(dragState) => {
          if (dragState.dragging.id === dragState.target.id) {
            return;
          }
          setState((s) => {
            const lessons = [...s.lessons];
            const fromIndex = lessons.findIndex((l) => l.id === dragState.dragging.id);
            const [lesson] = lessons.splice(fromIndex, 1);
            const toIndex = lessons.findIndex((l) => l.id === dragState.target.id);
            lessons.splice(dragState.direction === 'after' ? toIndex + 1 : toIndex, 0, lesson);
            return { ...s, lessons };
          });
        }}
        canHandleDrop={(dragState, table) => dragState.dragging.table === table}
      >
        <header class="px-2">
          <PageTitle>Pages</PageTitle>
        </header>
        {lessons.map((l, i) => (
          <Draggable key={l.id} id={l.id} table="lessons" class="flex w-full group relative">
            <a
              key={l.id}
              href={`/manage/products/${product.id}/content/${l.id}`}
              class={`p-2 pr-6 rounded-md text-inherit hover:bg-gray-100 whitespace-nowrap text-ellipsis overflow-hidden grow ${
                l.id === lesson?.id ? 'bg-gray-100' : ''
              }`}
            >
              {l.title || 'Untitled page'}
            </a>
            <Dropdown
              triggerClass={`${
                l.id === lesson?.id ? '' : 'hidden'
              } group-hover:flex absolute right-0 inset-y-0 w-6 rounded-md`}
              hideDownIcon
              renderMenu={() => {
                return (
                  <div class="flex flex-col p-2 pb-0">
                    <MenuItem
                      onClick={() => {
                        showModalForm(({ resolve }) => (
                          <CopyModal
                            type="lesson"
                            item={l}
                            onClose={resolve}
                            refreshOutline={async () => {
                              try {
                                const { lessons } = await load(props.route);
                                setState((s) => ({ ...s, lessons }));
                              } catch (err) {
                                showError(err);
                              }
                            }}
                          />
                        ));
                      }}
                    >
                      Copy Page
                    </MenuItem>
                    <MenuItem
                      onClick={async () => {
                        try {
                          const ok = await showDialog({
                            mode: 'warn',
                            title: 'Delete Page?',
                            children: 'This action cannot be undone.',
                            confirmButtonText: 'Delete Page',
                          });
                          if (!ok) {
                            return;
                          }
                          await rpx.lessons.deleteLesson({
                            id: l.id,
                            courseId: product.id,
                          });
                          // Remove the lesson from our in-memory list
                          setState((s) => ({
                            ...s,
                            lessons: s.lessons.filter((x) => x.id !== l.id),
                          }));
                          // Goto the next lesson
                          const nextLesson = lessons[i + 1] || lessons[i - 1] || lessons[0];
                          if (nextLesson?.id !== l.id) {
                            router.goto(`/manage/products/${product.id}/content/${nextLesson.id}`);
                          } else {
                            router.goto(`/manage/products/${product.id}`);
                          }
                          showToast({
                            type: 'ok',
                            title: 'Page deleted',
                            message: `Deleted "${l.title}".`,
                          });
                        } catch (err) {
                          showError(err);
                        }
                      }}
                    >
                      Delete Page
                    </MenuItem>
                  </div>
                );
              }}
            >
              <IcoDotsHorizontal class="rotate-90 w-4 h-4 opacity-75" />
            </Dropdown>
          </Draggable>
        ))}
      </DraggableProvider>
      <BtnPrimary class="rounded-md" onClick={props.createNewPage}>
        + Add Page
      </BtnPrimary>
    </nav>
  );
}

function Page(props: Props) {
  const { state, setState } = props;
  const { product, lesson, module } = state;
  const [showMenu, setShowMenu] = useState(false);

  const setContent = useCallback<LessonSetter>(
    (fn) => {
      setState((s) => {
        if (!s.lesson || s.lesson.id !== lesson?.id) {
          return s;
        }
        const newLesson = fn(s.lesson);
        const titleChanged = newLesson.title !== s.lesson.title;
        return {
          ...s,
          lessons: titleChanged
            ? s.lessons.map((l) => (l.id === newLesson.id ? { ...l, title: newLesson.title } : l))
            : s.lessons,
          lesson: newLesson,
        };
      });
    },
    [lesson?.id],
  );

  const loading = useLessonFetcher(product.id, props.route.params.lessonId, (lesson) =>
    setState((s) => ({ ...s, lesson })),
  );

  const createNewPage = async () => {
    try {
      let parent = module;
      if (!parent) {
        const newModule = {
          title: 'Pages',
          courseId: product.id,
          isDraft: false,
        };
        parent = {
          ...newModule,
          id: await modulesService.createModule({
            ...newModule,
            isAbsoluteSchedule: product.isAbsoluteSchedule,
          }),
        };
      }
      const newLesson = await rpx.lessons.createLesson({
        moduleId: parent.id,
        title: 'Untitled page',
        content: '',
        seq: state.lessons.reduce((n, b) => Math.max(n, b.seq), 0) || state.lessons.length + 1,
      });
      setState((s) => ({ ...s, lessons: [...s.lessons, newLesson], module: parent }));
      router.goto(`/manage/products/${product.id}/content/${newLesson.id}`);
    } catch (err) {
      showError(err);
    }
  };

  useDidUpdateEffect(() => setShowMenu(false), [props.route.params.lessonId]);

  if (!state.lessons.length) {
    return (
      <GuideProductPage
        page="content"
        product={product}
        menuPrefix={
          <Button class="inline-flex lg:hidden items-center" onClick={() => setShowMenu(true)}>
            <IcoList />
          </Button>
        }
      >
        <EmptyPage
          title="Content"
          description="You haven't created any content, yet!"
          Ico={IcoBook}
          actionText="Create Your First Page"
          onClick={createNewPage}
        />
      </GuideProductPage>
    );
  }

  return (
    <GuideProductPage
      page="content"
      product={product}
      menuPrefix={
        <Button
          class="inline-flex lg:hidden items-center ml-3 -mr-1"
          onClick={() => setShowMenu(true)}
        >
          <IcoList />
        </Button>
      }
    >
      {!!loading && loading !== lesson?.id && <LoadingIndicator />}
      <div class="grow flex gap-4">
        {showMenu && (
          <SlideOver p="p-2" w="" close={() => setShowMenu(false)} position="left">
            <PagesNav {...props} createNewPage={createNewPage} />
          </SlideOver>
        )}
        <div class="hidden sticky self-start h-screen overflow-auto top-0 lg:flex">
          <div class="relative border-r">
            <PagesNav {...props} createNewPage={createNewPage} />
          </div>
        </div>
        {lesson && (
          <section class="p-4 md:p-8 grow max-w-full" key={lesson.id}>
            <ContentEditor content={lesson} setContent={setContent} courseId={product.id} />
          </section>
        )}
      </div>
    </GuideProductPage>
  );
}

router.add({
  url: 'manage/products/:courseId/content',
  load,
  render: Page,
  authLevel: 'guide',
});

router.add({
  url: 'manage/products/:courseId/content/:lessonId',
  load,
  render: Page,
  authLevel: 'guide',
});
